import React, {useState, useRef, useEffect} from "react";

const AlertBox = (props) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetails = () => {
        setShowDetails(!showDetails);
    };

    if (!props.text) {
        return null
    }

    const trimmedText = props.text.replace(/<p class="MsoNormal">&nbsp;<\/p>/g, '');

    return (
      props.size < 960 ?
            <div>
                <div
                    style={{
                        border: "2px solid #01bac6",
                        padding: "10px 0 0",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        overflow: "hidden",

                    }}
                >
                    <p
                        style={{
                            margin: "0px 10px 10px 10px",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: showDetails ? "pre-line" : "nowrap",
                            maxHeight: showDetails ? "none" : "20px",
                        }}
                        dangerouslySetInnerHTML={{__html: trimmedText}}
                    />
                    <div
                        style={{
                            backgroundColor: "#a0f0ff",
                            textAlign: "center",
                            padding: "5px 0",
                            borderRadius: "0 0 6px 6px",
                        }}
                    >
                        <button
                            onClick={toggleDetails}
                            style={{
                                backgroundColor: "#a0f0ff",
                                color: "#01bac6",
                                fontWeight: "800",
                            }}
                        >
                            {showDetails ? "Скрыть" : "Читать далее"}
                        </button>
                    </div>
                </div>
            </div> :
            <div style={{
                border: "2px solid #01bac6",
                padding: "10px 0 0",
                backgroundColor: "white",
                borderRadius: "10px"
            }}>
                <p style={{margin: "0px 10px 10px 10px"}} dangerouslySetInnerHTML={{__html: trimmedText}}/>
            </div>

    );
};

export default AlertBox;
