import React, { useState, useEffect } from "react";
import { useWindowSize } from "../hooks";
import moment from "moment";
import DatePicker, { registerLocale } from "react-datepicker";
import history from "../history";
import RoomSelect from "./RoomSelect";
import GuestSelect from "./GuestSelect";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import ContainerNear from "./ContainerNear/ContainerNear";
import HotelListModal from "./HotelListModal";
import {IMAGES_ARR} from "./images";
import IconButton from "../shared/IconButton/IconButton";
import { names } from "../../Styles/names";

registerLocale("ru", ru);

const shouldShowBtnHotelChanging = process.env.REACT_APP_SHOULD_SHOW_BTN_HOTEL_CHANGING === 'true';
const shouldShowCalendarSwitch = process.env.REACT_APP_SHOULD_SHOW_CALENDAR_SWITCH === 'true';
const shouldShowAlertSwitch = process.env.REACT_APP_SHOULD_SHOW_ALERT_SWITCH === 'true';

function SearchForm(props) {
  const size = useWindowSize();
  const [promo, setPromo] = useState(props?.promo || "");
  const [placeholderVisible, setPlaceholderVisible] = useState(true);
  const [hotelData, setHotelData] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [hasError, setHasError] = useState({error: false, message: ""});
  const [showFuture, setShowFuture] = useState(false);
  const [dataLoading, setDataLoading] = useState(false);
  const [showRate, setShowRate] = useState({});
  const [availableDates, setAvailableDates] = useState([]);
  const rates_checked = props.rates.filter((rate) => rate.check);
  const [promoInfo, setPromoInfo] = useState(null);
  const [updateNearDates, setUpdateNearDates] = useState(true)
  const [urlParams, setUrlParams] = useState('')
  const [isNearPricesShown, setIsNearPricesShown] = useIsNearPriceShown()
  const [isCalendarButtonShown, setIsCalendarButtonShown] = useState(false)


  let child_max_age = 0;
  let baby_max_age = 0;


  let ageOptions = [
    { value: 0, label: "до 1 года" },
    { value: 1, label: "1 год" },
    { value: 2, label: "2 года" },
    { value: 3, label: "3 года" },
    { value: 4, label: "4 года" },
  ];

  let { startDate, endDate, roomTypeId, rateId, fromUrl } = props;

  for (var i = 0; i <= hotelData.child_max_age; i++) {
    if (i > 4) {
      ageOptions.push({ value: i, label: i + " лет" });
    }
  }

  /*
    Параметры для модалки смены отелей.
    Берем из урл, а не из пропсов, т.к должны менятся только после нажатия кнопки найти
  */
  const url = new URL(window.location.href);
  const paramBabies = url.searchParams.get("babies");
  const paramAdult = url.searchParams.get("adults");
  const paramChild = url.searchParams.get("child");
  const paramStart = moment(url.searchParams.get("date"));
  const paramEnd = moment(url.searchParams.get("date_out"));

  const timeHandler = () => {
    props.setStartDate(Date.parse(showRate.date_start));
    props.setEndDate(Date.parse(showRate.date_end));
    setShowFuture(false);
  };

  useEffect(() => {
    //из массива возрастов детей делаем строчку для вставки в юрл
    const childrenString = props?.childCount?.join(';');

   if (urlParams !== '') {
      history.push(urlParams + "&children-age=" + childrenString);
    }
  }, [urlParams, props?.childCount])

  function toggleItem(id) {
    props.onRatesChange(
      [...props.rates].map((rate) => {
        if (rate.id === id) {
          rate.check = !rate.check;
          !rate.check && showFuture && setShowFuture(false);
          let start = new Date(rate.date_start);
          if (rate.check && rate.date_start && start > props.startDate) {
            setShowFuture(true);
            setShowRate(rate);
          }
        }
        return rate;
      })
    );
  }

  const ratesBtns = props.rates.map((val, i) => {
    let bgClass = "";
    let bgStyle = {};
    bgStyle.display = showFilter ? "inline-flex" : "none";
    if (val.background) {
      bgStyle.backgroundImage =
        "linear-gradient(0deg, rgba(72, 65, 81, .6), rgba(72, 65, 81, .6)), url('" +
        process.env.PUBLIC_URL +
        val.background +
        "')";
      bgClass = " has-bg";
    }

    // val.currency = "₽";

    return (
      <button
        className={`filter-form__btn check-btn ur-mr-1 ur-mb-1${bgClass}${
          val.check ? " checked" : ""
        }`}
        style={bgStyle}
        key={i}
        onClick={() => toggleItem(val.id)}
      >
        <i className="icon icon-check"></i>
        <span>{val.name}</span>
        {/*<span className="price">*/}
        {/*  {String(val.price).toLocaleString()} {val.currency}*/}
        {/*</span>*/}
        <span className="info">
          <i
            className="icon icon-info-circle"
            title={
              String(val.description)
                .replace(/<\/?[^>]+>/gi, "")
                .replace(/&nbsp;/g, " ")
                .replace(/&ndash;/g, "–")
            }
          ></i>
        </span>
      </button>
    );
  });

  const ratesBtnsModal = props.rates.map((val, idx) => {
    return (
      <button
        className={`filter-form__btn check-btn ${val.check ? " checked" : ""}`}
        key={idx}
        onClick={() => toggleItem(val.id)}
      >
        <i className="icon icon-check"></i>
        <span>{val.name}</span>
      </button>
    );
  });

  const getAvailableDates = (fromUrl) => {
    props.onLoaderChange(true);
    props.setAlt(null)

    fetch(process.env.REACT_APP_API_URL_DIRECTLY + "api/rooms/avail_dates?hotel_id=" + props.hotelId, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    })
      .then((res) => res.json())
      .then((result) => {
        console.log('Have data');
        props.setAlertText(result?.alert_box)
        const availableDates = result.available_dates
          .filter(
            (room) => roomTypeId === 0 || room.room_type_id === roomTypeId
          )
          .reduce((acc, curr) => {
            if (!acc[0] || acc[0].room_type_id !== curr.room_type_id) {
              acc.unshift({
                room_type_id: curr.room_type_id,
                available_dates: { [curr.date]: curr.amount },
              });
            } else {
              acc[0].available_dates[curr.date] = curr.amount;
            }
            return acc;
          }, []);

        const dates = new Set();
        for (const room of availableDates) {
          for (const date in room.available_dates) {
            if (room.available_dates[date] >= 1) {
              dates.add(new Date(date));
            }
          }
        }

        console.log('Calculate dates and set available dates');

        setAvailableDates([...dates]);

        const isAvailableRooms = (start_date, end_date) => {
          let result = false;

          for (const room of availableDates) {
            const available_dates = Object.entries(room.available_dates)
              .map(([date, amount]) => {
                const moment_date = moment(date);

                if (start_date <= moment_date && end_date >= moment_date) {
                  return amount;
                }

                return null;
              })
              .filter((date) => typeof date == "number");

            if (end_date.diff(start_date, "days") === available_dates.length) {
              result = true;
            }
          }

          return result;
        };

        const start_date = moment(startDate);
        const end_date = moment(endDate);

        if (!isAvailableRooms(start_date, end_date)) {
          const available_dates = [];

          for (let i = 0; i < 90; i++) {
            const _start_date = start_date.add(i, "d");
            const _end_date = end_date.add(i, "d");

            if (isAvailableRooms(_start_date, _end_date)) {
              available_dates.push([
                _start_date.format("DD.MM.yyyy"),
                _end_date.format("DD.MM.yyyy"),
              ]);
            }

            if (available_dates.length >= 2) {
              break;
            }
          }

          props.setAvailableDates(available_dates);
        } else {
          props.setAvailableDates([]);
        }

        console.log('Set rates');

        if (result.rates) {
          props.onRatesChange(result.rates);
        }


        const url = new URL(window.location.href);
        rateId = +url.searchParams.get("rate_id");

        if(rateId>0) { // в урл есть ид тарифа

          let tariff_from_date = '';

          result.rates.forEach(function (element, idx) {

            if(element.id == rateId) {
              console.log('Rate start from '+element.date_start);

              tariff_from_date =  moment(element.date_start);
              if(start_date < tariff_from_date ) {

                startDate = new Date(tariff_from_date) ;
                endDate = new Date(moment(tariff_from_date).add(1, "days"));
                console.log('start dati is changed to '+startDate);
                props.onDateChange([startDate, endDate]);
                fromUrl = false;
              }
            }
          });

        }

        props.setRoomTypes(result.room_types);
        setHotelData({
          name: result.name,
          url: result.url,
          active: result.active,
          stars: result.data.stars,
          address: result.data.address,
          baby_max_age: result.data.baby_max_age,
          child_max_age: result.data.child_max_age,
          hotelsList: result.urls,
        });

        console.log('Set hotels data');

        baby_max_age = result.data.baby_max_age;
        child_max_age = result.data.child_max_age;

        exclusionAdults(fromUrl, result.data.child_max_age)

      })
      .catch((error) => setHasError({ error: true, message: error.message }));
  };

  const exclusionAdults = (fromUrl, child_max_age) => {
    const arrAge = [];
    let plusAdults = 0;
    let newAdults = props.adultCount
    if (props?.childCount?.length > 0) {
      for (const el of props.childCount) {
        if (el > child_max_age) {
          plusAdults++
        } else {
          arrAge.push(el);
        }
      }
    }

    if (plusAdults > 0) {
      props.onAdultCount(pr => pr + plusAdults);
      newAdults += plusAdults;
    }

    if (arrAge.length > 0) {
      props.onChildCount(arrAge);
    }

    getRooms(fromUrl, arrAge, newAdults);
  };




  const loadDataFromUrl = async () => {
    if (window.location.search) {
      const url = new URL(window.location.href);

      const currentDate = moment().format('YYYY-MM-DD');
      const dateParam = url.searchParams.get("date");
      const dateOutParam = url.searchParams.get("date_out");

      startDate = dateParam && dateParam >= currentDate ? new Date(dateParam) : new Date();

      if (dateParam && dateOutParam) {
        const dateDifference = moment(dateOutParam).diff(moment(dateParam), 'days');
        const daysToAdd = Math.max(dateDifference, 0);
        if (dateParam < currentDate) {
          endDate = moment(currentDate).add(daysToAdd, 'days').toDate();
        } else {
          endDate = moment(dateOutParam)
        }
      } else {
        endDate = moment(startDate).add(1, 'days').toDate();
      }

      roomTypeId = +url.searchParams.get("room_type_id");
      rateId = +url.searchParams.get("rate_id");

      await props.onDateChange([startDate, endDate]);

      if (url.searchParams.get("children-age")) {
        onChildAgeCount(url.searchParams.get("children-age")); // children-age=2;3;10
      }

      props.onRateIdChange(+rateId);
      props.onRoomTypeIdChange(roomTypeId);
      if(url.searchParams.get("adults")) {
        props.onAdultCount(+url.searchParams.get("adults"));
      } else {
        props.onAdultCount(2)
      }
    }
    if (!window.isLoaded) {
      window.isLoaded = true;
    } else {
      console.log("Data is loading!");
      getAvailableDates(true);
    }
  };

  useEffect(loadDataFromUrl, []);

  function getRooms(fromUrl, arrAge, newAdults) {
    let children_info = {};
    let children = 0;
    let babies = 0;

    arrAge.forEach(function (element, idx) {
      children_info["age" + (idx + 1)] = element;
      if (element <= hotelData.baby_max_age) {
        babies++;
      } else if (element <= hotelData.child_max_age) {
        children++;
      }
    });

    children_info.quantity = arrAge.length;

    let params = {
      hotel_id: props.hotelId,
      date_in: moment(startDate).format("YYYY-MM-DD"),
      date_out: moment(endDate).format("YYYY-MM-DD"),
      adults: newAdults,
      children: children,
      babies: babies,
      room_type_id: roomTypeId,
      rooms: 1,
      children_info: children_info,
      promo,
      children_str: ""
    };

    if (window.location.search && fromUrl) {
      console.log("get data from URL");
      const url = new URL(window.location.href);

      // это мешало восстановлению даты, когда дата заезда меньше текущей. Пока закомментил - понаблюдаем.
      // if(url.searchParams.get("date")) {
      // params.date_in = moment(new Date(url.searchParams.get("date"))).format("YYYY-MM-DD");
      // } else {
      //   params.date_in = moment(new Date()).format("YYYY-MM-DD");
      // }
      //
      // if(url.searchParams.get("date_out")) {
      //   params.date_out = moment(new Date(url.searchParams.get("date_out"))).format("YYYY-MM-DD");
      // } else {
      //   params.date_out = moment(new Date(moment().add(1, "days"))).format("YYYY-MM-DD");
      // }

      if(url.searchParams.get("adults")) {
        params.adults = +url.searchParams.get("adults");
      } else {
        params.adults = 2;
      }

      rateId = +url.searchParams.get("rate_id");

      params.children = +url.searchParams.get("child");
      params.babies = +url.searchParams.get("babies");

      if (url.searchParams.get("children-age")) {

        params.children_str = url.searchParams.get("children-age");
        params.children = 0;
        params.babies = 0;

        const arr = url.searchParams.get("children-age").split(";");

        let ageIndex = 0;
        let childrenArray = [];
        let changeAdults = false;

        arr.forEach(function (element) {
          ageIndex++;

          if (element <= baby_max_age) {
            params.children_info["age" + ageIndex] = element;
            params.babies++;
            childrenArray.push(element);
          } else if (element <= child_max_age) {
            params.children_info["age" + ageIndex] = element;
            params.children++;
            childrenArray.push(element);
          } else {
            params.adults++;
            changeAdults = true;
          }
        });
        if(changeAdults) {
          onChildAgeCount(childrenArray.join(';'));
          props.onAdultCount(+params.adults);
          params.children_str = childrenArray.join(';');
        }
        params.children_info.quantity = params.babies + params.children;
      }
      params.room_type_id = +url.searchParams.get("room_type_id");
    }


    //@FIXME костыль
    window.babiesCount = params.babies;

    //из массива возрастов детей делаем строчку для вставки в юрл
    const childrenString = props?.childCount?.join(';');

    const url_params =
        "?date=" +
        params.date_in +
        "&date_out=" +
        params.date_out +
        "&adults=" +
        params.adults +
        "&child=" +
        params.children +
        "&babies=" +
        params.babies +
        "&rooms=" +
        params.rooms +
        "&room_type_id=" +
        roomTypeId +
        "&rate_id=" +
        rateId

    setUrlParams(url_params)


    fetch(`${process.env.REACT_APP_API_URL_DIRECTLY}api/rooms/search`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },mode:"cors",
      body: JSON.stringify(params),
    })
      .then((res) => res.json())
      .then((result) => {
        props.setSubscriptionParams(params)

        if (!result.length || result[0]?.altText) {
          if (result[0]?.altText) {
            props.setAlt(result[0].altText)
          } else props.setAlt(null)

          props.onLoaderChange(false);
          props.onResultChange(result)
          return;
        }
        if (result[0]?.notPlaceAltText) {
          props.setAlt(result[0].notPlaceAltText)
        } else props.setAlt(null)

        let room = {};
        let groups = [];

        if (result[0]?.promoInfo) {
          setPromoInfo(result[0]?.promoInfo)
        } else setPromoInfo(null)

        result.forEach((number) => {
          if (rateId !== 0 && number.rate_id !== rateId) {
            return;
          }
          let existingGroups = groups.filter(
            (group) => group.room_type_id === number.room_type_id
          );
          let flag = false;
          if (rates_checked.length > 0) {
            rates_checked.forEach((rate) => {
              if (rate.id === +number.rate_id) {
                flag = true;
              }
            });
          } else {
            flag = true;
          }

          if (flag) {
            if (existingGroups.length > 0) {
              let images;

              try {
                const cleanedString = number?.room_type_images.replace(/[\x00-\x1F\x7F]/g, '');
                images = JSON.parse(cleanedString)?.sort((a, b) => a.order - b.order) ?? null;
              } catch (e) {
                images = null;
                console.error(e);
              }

              existingGroups[0].image = images?.[0] ?? '';
              existingGroups[0].gallery_images = images ?? [];

              existingGroups[0].rate_group.push({
                promo: number.promo,
                room_id: number.room_type_id,
                rate_id: number.rate_id,
                rate_name: number.rate_name,
                rate_description: number.rate_description,
                rate_bg: number.rate_bg,
                rate_price: number.total,
                rate_services: number.services,
                max_stay: number.max_stay,
                multiple_days: number.multiple_days,
                extraBed: number.extra_bed,
                craddle: number.craddle,
                prices: number.prices,
                dateIn: number.date_in,
                dateOut: number.date_out,
                resource_id: number.resource_id,
                discount: number.discount,
                tariff_characteristics: number.tariff_characteristics,
                order_rate: number.order_rate,
                room_rates: number.room_rates,
                promo_id: number?.promo_id,
                total_discount: number?.total_discount,
                quotas_vacant: number.quotas_vacant,
                own_quotas: number.own_quotas,
                stocks: number.stocks,
                extraPlace: number.extraPlace,
                cradlePlace: number.cradlePlace,
                tariff_after: number?.tariff_after,
                tariff_before: number?.tariff_before,
                loyalty: number.loyalty,
                payment_methods_oob: number.payment_methods_oob
              });
            } else {
              room = { ...number };
              delete room.rate_description;
              delete room.rate_id;
              delete room.rate_name;
              delete room.services;
              delete room.promo;
              room.hotel_info = hotelData;
              room.children_info = children_info;

              let images;

              try {
                const cleanedString = number?.room_type_images.replace(/[\x00-\x1F\x7F]/g, '');
                images = JSON.parse(cleanedString)?.sort((a, b) => a.order - b.order) ?? null;
              } catch (e) {
                images = null;
                console.error(e);
              }

              room.image = images?.[0] ?? '';
              room.gallery_images = images ?? [];

              room.rate_group = [
                {
                  promo: number.promo,
                  room_id: number.room_type_id,
                  rate_id: number.rate_id,
                  rate_name: number.rate_name,
                  rate_description: number.rate_description,
                  rate_bg: number.rate_bg,
                  rate_price: number.total,
                  rate_services: number.services,
                  max_stay: number.max_stay,
                  multiple_days: number.multiple_days,
                  extraBed: number.extra_bed,
                  craddle: number.craddle,
                  prices: number.prices,
                  dateIn: number.date_in,
                  dateOut: number.date_out,
                  resource_id: number.resource_id,
                  tariff_characteristics: number.tariff_characteristics,
                  order_rate: number.order_rate,
                  room_rates: number.room_rates,
                  promo_id: number?.promo_id,
                  total_discount: number?.total_discount,
                  quotas_vacant: number.quotas_vacant,
                  own_quotas: number.own_quotas,
                  stocks: number.stocks,
                  extraPlace: number.extraPlace,
                  cradlePlace: number.cradlePlace,
                  tariff_after: number?.tariff_after,
                  tariff_before: number?.tariff_before,
                  loyalty: number.loyalty,
                  payment_methods_oob: number.payment_methods_oob
                },
              ];
              groups.push(room);
            }
          }
        });

        // groups.forEach((number, idx) => {
        //   const room = IMAGES_ARR[props.resourceId].find(
        //       (room) => room.id === number.resource_id
        //   );
        //   if (room) {
        //     groups[idx].image = room.image;
        //     groups[idx].gallery_images = room.gallery_images;
        //
        //   } else {
        //       groups[idx].image = '';
        //       groups[idx].gallery_images = [];
        //   }
        // });

        props.onResultChange(groups);
        props.onLoaderChange(false);

        // groups.forEach((number, idx) => {
        //   groups[idx].image = '';
        //   groups[idx].gallery_images = [];
        //
        // });
        // props.onResultChange(groups);
        // props.onLoaderChange(false);
        // fetch(
        //   new URL(
        //     `${process.env.REACT_APP_API_URL_IMAGE}hotel_images.json?resource=${props.resourceId}`
        //   )
        // )
        //   .then((res) => res.json())
        //   .then((result) => {
        //     groups.forEach((number, idx) => {
        //       const room = result.find(
        //         (room) => room.id === number.resource_id
        //       );
        //       if (room) {
        //         groups[idx].image = room.image;
        //         groups[idx].gallery_images = room.gallery_images;
        //
        //       }
        //     });
        //     props.onResultChange(groups);
        //     props.onLoaderChange(true);
        //   })
        //   .finally(() => {
        //     props.onLoaderChange(false);
        //   })
        //   .catch((error) => {
        //         console.error(error)
        //         // setHasError({ error: true, message: error.message })
        //       }
        //   );
      })
      .catch((error) => {
            console.error(error)
            // setHasError({ error: true, message: error.message })
          }
      );
  }

  function handleChildChange(id, val) {
    const arr = [...props.childCount];
    arr[id] = val.value;
    props.onChildCount(arr);
  }

  function onChildRemove(idx) {
    const arr = [...props.childCount];
    arr.splice(idx, 1);
    props.onChildCount(arr);
  }

  function onChildAgeCount(age) {
    const arr = age.split(";");
    if(arr[0]=='') {
      props.onChildCount([])
    }
    props.onChildCount(arr);
  }

  const endDateSafe = () => {
    if (props.endDate) {
      return moment(props.endDate).format("DD.MM.YYYY");
    }
    return "";
  };

  const renderDayContents = (day) => {
    return <div>{day}</div>;
  };

  const detectCalendarOrientation = () =>
  {
    if (size.width < 576) {
      return true;
    } else {
      return false;
    }
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      getAvailableDates(false);
    }
  }

  const datepicker = (

   <React.Fragment>
          {detectCalendarOrientation() ?
          <DatePicker
              className="content"
              selected={props.startDate}
              dateFormat="dd.MM.yyyy"
              renderDayContents={renderDayContents}
              onChange={(dates) => props.onDateChange(dates)}

              // includeDates={availableDates}
              selectsDisabledDaysInRange
              selectsRange
              startDate={props.startDate}
              endDate={props.endDate}
              minDate={new Date()}
              locale="ru"
              monthsShown={15}
              inline
            /> :
            <DatePicker
              className="content"
              selected={props.startDate}
              dateFormat="dd.MM.yyyy"
              renderDayContents={renderDayContents}
              onChange={(dates) => props.onDateChange(dates)}
              // includeDates={availableDates}
              selectsDisabledDaysInRange
              selectsRange
              startDate={props.startDate}
              endDate={props.endDate}
              minDate={new Date()}
              locale="ru"
              monthsShown={2}
              inline
            />
            }
   </React.Fragment>
  );

  const content = props.isContentHidden ? null : (
    <React.Fragment>
      <div className={`ur-container ur-mt-3 ${names.backgrond.primary}`}>
        {process.env.REACT_APP_UP_NAVIGATION === 'true' && (
            <div className="steps-container">
              <span className="step-number">1.&nbsp;</span>
              <span className="step-text">Выбор номера и тарифа</span>
              <span className="step-arrow">&#8594;</span>
              <span className="inactive-step-number">2.&nbsp;</span>
              <span className="inactive-step-text">Оформление заказа</span>
            </div>
        )
        }
        <div className="ur-row search-form ur-pb-3">
          <div className="search-form__wrapper">
            <div className="search-form__input-date-in">
              <div className={`title ${names.text.tertiaryDark}`}>Заезд</div>
              <div
                className="content"
                onClick={() => props.onShowDatepickerChange(true)}
              >
                {moment(props.startDate).format("DD.MM.YYYY")}
              </div>
            </div>
            <div className="search-form__input-date-out">
              <div className={`title ${names.text.tertiaryDark}`}>Выезд</div>
              <div
                className={`content ${names.text.secondary}`}
                onClick={() => props.onShowDatepickerChange(true)}
              >
                {endDateSafe()}
              </div>
            </div>
            <RoomSelect
              roomTypes={props.roomTypes}
              roomTypeId={roomTypeId}
              onRoomTypeIdChange={props.onRoomTypeIdChange}
            />
            <GuestSelect
              onAdultChange={props.onAdultCount}
              onChildChange={handleChildChange}
              onChildRemove={(idx) => onChildRemove(idx)}
              ageOptions={ageOptions}
              adult={props.adultCount}
              child={props.childCount}
            />
            <button
              className={`search-form__button-submit ${names.backgrond.button.primary}  ${names.text.negative}`}
              onClick={() => {
                getAvailableDates(false)
                setUpdateNearDates(true)
                props.setIsDataTimeout(false)
              }}
              disabled={props.loader}
            >
              <i className="icon icon-search"></i> Найти
            </button>
          </div>
        </div>
        <div className="ur-row filter-form">
          <div className="filer-promo__input-wrapper">
            {placeholderVisible && promo == '' &&
              <label className={`filer-promo__placeholder ${names.text.tertiary}`}>
                  Если у вас есть промокод, введите его на этом этапе
                </label>
            }
            <input
                value={promo}
                className="filter-form__btn filter-btn ur-mr-1 ur-mb-1 filer-promo_input"
                onChange={({target: {value}}) => setPromo(value)}
                onFocus={() => setPlaceholderVisible(false)}
                onBlur={() => {
                  if (!promo) {
                    setPlaceholderVisible(true)
                  }
                }}
                placeholder=""
                type="text"
                onKeyDown={handleKeyDown}
            />
            <button className={`search-form__button-promocode ur-mb-1 ${names.backgrond.button.primary} ${names.text.negative}`}
                    onClick={() => {
                      getAvailableDates(false)
                      setUpdateNearDates(true)
                    }}
                    disabled={props.loader}
            >Применить
            </button>
            {props.isAlertText && shouldShowAlertSwitch &&
              <IconButton
                onPress={() => props.setIsAlertBoxShown(value => !value)}
                className={'w60'}
                iconClassName={'warning'}
              />
            }

            {isCalendarButtonShown && shouldShowCalendarSwitch &&
              <IconButton
                onPress={() => setIsNearPricesShown(value => !value)}
                className={'w60'}
                iconClassName={'calendar'}
              />
            }


            {shouldShowBtnHotelChanging && <HotelListModal
            startDate={paramStart}
            endDate={paramEnd}
            adultsCount={+paramAdult}
            children={+paramChild}
            babiesCount={paramBabies}
            hotelId={props.hotelId}
            />}
        </div>
          {promoInfo?.promoDateFalse && (
              <div className="search-form__error-message">
                Извините, но по промокоду "<span class="search-form__promo-name">{promoInfo.name}</span>" на выбранные даты нет доступных предложений.
              </div>
          )}
          {promoInfo?.promoActiveFalse && (
              <div className="search-form__error-message">
                Извините, промокод "<span class="search-form__promo-name">{promoInfo.name}</span>" уже использован. Повторное применение данного промокода невозможно.
              </div>
          )}
          {promoInfo?.promoExistFalse && (
              <div className="search-form__error-message">
                Промокод "<span class="search-form__promo-name">{promoInfo.name}</span>" не найден.
              </div>
          )}

          <button
            className={`search-form__button-submit ${names.backgrond.button.primary}  ${names.text.negative}`}
            onClick={() => {
              getAvailableDates(false)
              setUpdateNearDates(true)
              props.setIsDataTimeout(false)
            }}
            disabled={props.loader}
          >
            <i className="icon icon-search"></i> Найти
          </button>
          {/* </div>
        <div className="ur-row filter-form"> */}
          {props.size.width > 576 ? (
            ratesBtns
          ) : (
            <div className={`modal-rates${showFilter ? " show" : ""}`}>
              <div className="modal-rates__header">
                <div className="title">Фильтр</div>
                <button className="close" onClick={() => setShowFilter(false)}>
                  &times;
                </button>
              </div>
              {ratesBtnsModal}
              <div className="modal-rates__footer">
                <button
                  className="ur-btn ur-btn-apply"
                  onClick={() => setShowFilter(false)}
                >
                  Применить
                </button>
              </div>
            </div>
          )}
        </div>
        <ContainerNear
          startDate={props.startDate}
          endDateObject={props.endDate}
          setStartDate={props.setStartDate}
          setEndDate={props.setEndDate}
          id={props.hotelId}
          adults={props.adultCount}
          children={props.childCount}
          baby_max_age={hotelData?.baby_max_age}
          child_max_age={hotelData?.child_max_age}
          onDateSelect={() => getAvailableDates(false)}
          onDefaultLoad={() => setUpdateNearDates(false)}
          updateTrigger={updateNearDates}
          roomTypeId={props.roomTypeId}
          rateId={props.rateId}
          promo={promo}
          isContentShown={isNearPricesShown}
          onEnoughDataToShow={setIsCalendarButtonShown}
        />
      </div>

      {showFuture && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h6
            style={{
              color: "#FFFFFF",
              background: "#FF2E00",
              borderRadius: "5px",
              padding: "1px 8px",
            }}
          >
            Тариф {showRate.name} доступен с {showRate.date_start} по{" "}
            {showRate.date_end}
          </h6>
          <button
            onClick={timeHandler}
            style={{
              color: "green",
              fontWeight: "600",
              background: "yellow",
              borderRadius: "5px",
              padding: "1px 8px",
            }}
          >
            Установить данный промежуток
          </button>
        </div>
      )}

      <div
        className={`ur-modal__datepicker${props.showDatepicker ? " show" : ""}`}
      >
        <div className={`ur-modal__datepicker-modal-dialog ${names.backgrond.primary}`}>
          <div className="ur-modal__datepicker-header">
            <div className={`ur-modal__datepicker-header-head ${names.border.tertiaryDark}`}>
              <div className={`title ${names.text.secondary}`}>Даты бронирования</div>
              <button
                className={`close ${names.text.accentNegative}`}
                onClick={() => props.onShowDatepickerChange(false)}
              >
                &times;
              </button>
            </div>
            <div className="ur-modal__datepicker-header-date-inputs">
              <div className={`ur-modal__datepicker-date ${names.text.primary}`}>
                Заезд:&nbsp;<span>{moment(props.startDate).format("DD.MM.YYYY")}</span>
              </div>
              <div className={`ur-modal__datepicker-date ${names.text.primary}`}>
                Выезд:&nbsp;<span>{endDateSafe()}</span>
              </div>
            </div>
          </div>
          <div className="ur-modal__datepicker-body">
            {datepicker}
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      {hasError.error ? (
        <React.Fragment>
          <h2 style={{ textAlign: "center" }}>Ой, что-то пошло не так!</h2>
          <small
            style={{ textAlign: "center", display: "block", color: "#ccc" }}
          >
            {hasError.message}
          </small>
        </React.Fragment>
      ) : (
        content
      )}
    </React.Fragment>
  );
}

export default SearchForm;




const useIsNearPriceShown = (defaultValue = !shouldShowCalendarSwitch) => {
  const [isNearPricesShown, setIsNearPricesShown] = useState(defaultValue)
  const { width } = useWindowSize()

  useEffect(() => {
    if (width < 960) {
      setIsNearPricesShown(true)
    }
  }, [width])

  return [isNearPricesShown, setIsNearPricesShown]
}