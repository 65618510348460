import React, { useState, useEffect } from 'react';
import moment from "moment";
import './ContainerNear.css';
import { useWindowSize } from '../../hooks';
import IconButton from '../../shared/IconButton/IconButton';
import { names } from '../../../Styles/names';
import theme from '../../../Styles/theme.json';

const shouldShowCalendarSwitch = process.env.REACT_APP_SHOULD_SHOW_CALENDAR_SWITCH === 'true';


const useDisplayedDaysCount = () => {
    const { width } = useWindowSize()
    if (width) {
        if (width < 768) {
            return 3
        } else if (width < 1024) {
            return 5
        } else {
            return 7
        }
    }
    return 7
}

const fetchMinPrice = (fetchBody) => {
    return fetch(process.env.REACT_APP_API_URL_DIRECTLY + 'api/hotels/min_price', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json;charset=utf-8'
        },
        body: JSON.stringify(fetchBody)
    })
}

const getNextDate = (date, daysCount = 1) => {
    const nextDate = new Date(date.getTime())
    nextDate.setDate(date.getDate() + daysCount)
    return nextDate
}

const useDaysState = ({ adults, id, baby_max_age, child_max_age, children, roomTypeId, rateId, promo }) => {
    let babiesCount = 0
    let childrenCount = 0
    let adultsCount = adults
    children.forEach(age => {
        if (age <= baby_max_age) {
            babiesCount++
        } else if (age <= child_max_age) {
            childrenCount++
        } else adultsCount++
    });

    const defaultState = {
        days: {},
        lastIndex: undefined,
        firstIndex: undefined,
        length: 0
    }
    const [daysState, setDaysState] = useState(defaultState)
    const [lowestPrice, setLowestPrice] = useState(Infinity)
    const dates = daysState.days
    const setDefaultDays = () => setDaysState(defaultState)
    const addNewDay = ({ price, date }, atFirst = false) => {
        setDaysState(prevState => {
            let newLastIndex = prevState.lastIndex ?? 0
            let newFirstIndex = prevState.firstIndex ?? 0
            if (prevState.days[0]) {
                if (atFirst) newFirstIndex -= 1
                else newLastIndex += 1
            }

            const newIndex = atFirst ? newFirstIndex : newLastIndex
            loadDate(new Date(date), newIndex, roomTypeId, rateId, promo)
            return {
                lastIndex: newLastIndex,
                firstIndex: newFirstIndex,
                length: prevState.length + 1,
                days: {
                    ...prevState.days,
                    [newIndex]: {
                        price,
                        date
                    }
                }
            }
        })
    }
    const changeDay = ({ price, date }, index) => {
        setDaysState(prevState => {
            return {
                ...prevState,
                days: {
                    ...prevState.days,
                    [index]: {
                        price,
                        date
                    }
                }
            }
        })
    }

    const isDateLoaded = (date = new Date()) => {
        if (daysState.firstIndex !== undefined && daysState.lastIndex !== undefined) {
            const firstDate = new Date(dates[daysState.firstIndex].date)
            const lastDate = new Date(dates[daysState.lastIndex].date)
            if (firstDate <= date && date <= lastDate) {
                return true
            }
        }
        return false
    }
    const loadDate = (date = new Date(), index, roomTypeId, rateId, promo) => {
        const secondDate = getNextDate(date)

        fetchMinPrice({
            api_token: 'uVxMpwzW5tQQj6UaI9TYcMWMJljsw3n5MUoMjvsu4mUsvuaXetvgPzlLhiKa',
            hotel_id: id,
            adults: adultsCount,
            children: childrenCount,
            date_in: moment(date).format('YYYY-MM-DD'),
            date_out: moment(secondDate).format('YYYY-MM-DD'),
            babies: babiesCount,
            room_type_id: roomTypeId,
            rate_id: rateId,
            promo: promo
        })
            .then(result => result.json())
            .then(data => {
                const price = data[0]?.min_price ?? null
                changeDay({
                    price,
                    date: date.getTime()
                }, index)
                setLowestPrice(prevPrice => {
                    if (prevPrice && prevPrice > price) {
                        return price
                    } else {
                        return prevPrice
                    }
                })
            })
            .catch(err => console.log(err))
    }
    const loadDefaultDates = (defaultDate = new Date(), count = 7) => {
        setDefaultDays()
        const currentDate = new Date();
        defaultDate.setHours(0, 0, 0, 0);

        const timeDifference = Math.floor((defaultDate - currentDate) / (1000 * 60 * 60 * 24));

        let modifierIndex
        if (count === 5 || timeDifference === 1)
            modifierIndex = 2
        else if (count === 3 || timeDifference === 0)
            modifierIndex = 1
        else if (timeDifference === -1)
            modifierIndex = 0
        else modifierIndex = 3

        const modifierDate = new Date(defaultDate.getTime() - modifierIndex * 24 * 60 * 60 * 1000);

        for (let index = 0; index < count; index++) {
            const firstDate = getNextDate(modifierDate, index)

            addNewDay({
                price: undefined,
                date: firstDate.getTime()
            })
        }
    }

    const loadPrevDay = (index) => {
        if (dates[index - 1]) return true

        const firstDateInfo = dates[daysState.firstIndex]
        const firstDate = new Date(firstDateInfo.date)
        const today = new Date()
        today.setHours(0, 0, 0, 0)
        if (firstDate <= today) {
            return false
        }

        const newDate = getNextDate(firstDate, -1)
        addNewDay({
            price: undefined,
            date: newDate.getTime()
        }, true)

        return true
    }
    const loadNextDay = (index) => {
        if (dates[index]) return
        const lastDateInfo = dates[daysState.lastIndex]
        const lastDate = new Date(lastDateInfo.date)
        const newDate = getNextDate(lastDate)
        addNewDay({
            price: undefined,
            date: newDate.getTime()
        })
    }

    return {
        daysState,
        lowestPrice,
        loadPrevDay,
        loadNextDay,
        loadDefaultDates,
        isDateLoaded
    }
}
const useIsShown = (isContentShown, defaultValue = !shouldShowCalendarSwitch) => {

    const [isShown, setIsShown] = useState(defaultValue)
    const { width } = useWindowSize()

    useEffect(() => {
        if (width > 959) {
            setIsShown(isContentShown)
        }
    }, [isContentShown, width])

    return [isShown, setIsShown]
}

const ContainerNear = ({
    startDate,
    endDateObject,
    id,
    children,
    adults,
    onDateSelect,
    // baby_max_age = 3,
    // child_max_age = 6,
    baby_max_age,
    child_max_age,
    setEndDate,
    setStartDate,
    updateTrigger = false,
    onDefaultLoad,
    roomTypeId,
    rateId,
    promo,
    isContentShown,
    onEnoughDataToShow
}) => {
    const endDate = endDateObject?.setHours ? endDateObject : endDateObject?._d
    if (startDate) startDate.setHours(0, 0, 0, 0)
    if (endDate) {
        if (endDate.setHours)
            endDate.setHours(0, 0, 0, 0)

    }

    const {
        daysState,
        lowestPrice,
        loadPrevDay,
        loadNextDay,
        loadDefaultDates,
        isDateLoaded
    } = useDaysState({ adults, id, baby_max_age, child_max_age, children, roomTypeId, rateId, promo })
    const dates = daysState.days

    const [isShown, setIsShown] = useIsShown(isContentShown)

    const [selectedDate, setSelectedDate] = useState(startDate);
    const displayedDaysCount = useDisplayedDaysCount()

    const defaultIndexes = Array.from(Array(displayedDaysCount).keys())
    const [displayedDaysIndexes, setDisplayedDaysIndexes] = useState(defaultIndexes)
    const [updateHandle, setUpdateHandle] = useState(false);

    useEffect(() => {
        if (updateTrigger && child_max_age !== undefined && baby_max_age !== undefined) {
            if (onDefaultLoad) onDefaultLoad()
            setDisplayedDaysIndexes(defaultIndexes)
            loadDefaultDates(startDate)
            setSelectedDate(startDate)
        }
    }, [updateTrigger, child_max_age, baby_max_age])
    useEffect(() => {
        setDisplayedDaysIndexes(defaultIndexes)
    }, [displayedDaysCount])

    const handleHidePress = () => {
        setIsShown(value => !value)
    }

    const handleDate = (date) => {
        setUpdateHandle(true)
        const thisDate = new Date(date)
        setStartDate(thisDate);
        setSelectedDate(thisDate)
        const diffInMilliseconds = Math.abs(endDate - startDate);
        const diffInDays = Math.ceil(diffInMilliseconds / (1000 * 60 * 60 * 24));
        const parsedEndDate = new Date(thisDate.getTime() + (diffInDays * 24 * 60 * 60 * 1000));
        setEndDate(parsedEndDate);
    };

    useEffect(() => {
        if (updateHandle) {
            onDateSelect()
            setUpdateHandle(false)
        }
    }, [endDateObject])

    const handleAddDay = () => {
        const nextIndex = displayedDaysIndexes[displayedDaysIndexes.length - 1] + 1
        loadNextDay(nextIndex)
        setDisplayedDaysIndexes(prevIndexes => prevIndexes.map(index => index + 1))
    }
    const handleSubtractDay = () => {
        const firstIndex = displayedDaysIndexes[0]
        if (loadPrevDay(firstIndex)) {
            setDisplayedDaysIndexes(prevIndexes => prevIndexes.map(index => index - 1))
        }
    }

    const nonPrice =
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.41 8.99994L16.71 2.70994C16.8983 2.52164 17.0041 2.26624 17.0041 1.99994C17.0041 1.73364 16.8983 1.47825 16.71 1.28994C16.5217 1.10164 16.2663 0.99585 16 0.99585C15.7337 0.99585 15.4783 1.10164 15.29 1.28994L9 7.58994L2.71 1.28994C2.5217 1.10164 2.2663 0.99585 2 0.99585C1.7337 0.99585 1.4783 1.10164 1.29 1.28994C1.1017 1.47825 0.995909 1.73364 0.995908 1.99994C0.995908 2.26624 1.1017 2.52164 1.29 2.70994L7.59 8.99994L1.29 15.2899C1.19627 15.3829 1.12188 15.4935 1.07111 15.6154C1.02034 15.7372 0.994202 15.8679 0.994202 15.9999C0.994202 16.132 1.02034 16.2627 1.07111 16.3845C1.12188 16.5064 1.19627 16.617 1.29 16.7099C1.38296 16.8037 1.49356 16.8781 1.61542 16.9288C1.73728 16.9796 1.86799 17.0057 2 17.0057C2.13201 17.0057 2.26272 16.9796 2.38458 16.9288C2.50644 16.8781 2.61704 16.8037 2.71 16.7099L9 10.4099L15.29 16.7099C15.383 16.8037 15.4936 16.8781 15.6154 16.9288C15.7373 16.9796 15.868 17.0057 16 17.0057C16.132 17.0057 16.2627 16.9796 16.3846 16.9288C16.5064 16.8781 16.617 16.8037 16.71 16.7099C16.8037 16.617 16.8781 16.5064 16.9289 16.3845C16.9797 16.2627 17.0058 16.132 17.0058 15.9999C17.0058 15.8679 16.9797 15.7372 16.9289 15.6154C16.8781 15.4935 16.8037 15.3829 16.71 15.2899L10.41 8.99994Z" fill="#929292" />
        </svg>

    const displayedDays = displayedDaysIndexes.map(index => dates[index])

    if (daysState.length < 5 || !displayedDaysCount) {
        onEnoughDataToShow?.(false)
        return null
    } else {
        onEnoughDataToShow?.(true)
    }

    if (isContentShown) {
        return (
            <div className='ur-row-near-container'>
                {shouldShowCalendarSwitch &&
                    <IconButton
                        onPress={handleHidePress}
                        iconClassName={'calendar'}
                    />
                }

                {isShown &&
                    <div className={`ur-row-near ${names.backgrond.secondary}`}>
                        <div className="nearby-dates-form">
                            <button
                                className={"near-sliding near-sliding-left"}
                                onClick={handleSubtractDay}
                            >
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.500012 7.80005L6.20001 13.4C6.60001 13.8 7.20001 13.8 7.60001 13.4C8.00001 13 8.00001 12.4 7.60001 12L2.70001 7.00005L7.60001 2.00005C8.00001 1.60005 8.00001 1.00005 7.60001 0.600049C7.40001 0.400049 7.20001 0.300049 6.90001 0.300049C6.60001 0.300049 6.40001 0.400049 6.20001 0.600049L0.500012 6.20005C0.100012 6.70005 0.100012 7.30005 0.500012 7.80005C0.500012 7.70005 0.500012 7.70005 0.500012 7.80005V7.80005Z"
                                        fill={theme.icon.tertiary} />
                                </svg>
                            </button>

                            {displayedDays.map(({ date, price }, idx) => {
                                if (price === undefined) {
                                    return (
                                        <div
                                            key={date}
                                            className={`date-item-near`}
                                        >
                                            <div className="progress-circle" />
                                        </div>
                                    );
                                }
                                const dateThis = new Date(date)
                                dateThis.setHours(0, 0, 0, 0);
                                const isSelected = dateThis.getTime() === selectedDate.getTime();
                                const isMonthShort = [2, 4, 5, 6].find(num => num === new Date(date).getMonth()) === undefined
                                return (
                                    <div
                                        key={date}
                                        className={`date-item-near${isSelected && [' selected', names.border.accent, names.backgrond.primary].join(' ')}`}
                                        onClick={() => handleDate(date)}
                                    >
                                        <div className={`date-near ${isSelected ? names.text.accentNegative : names.text.tertiary}`}>
                                            {new Date(date).toLocaleString('ru-RU', {
                                                day: 'numeric',
                                                month: isMonthShort ? 'short' : 'long',
                                            })}
                                            ,
                                            {' ' + new Date(date).toLocaleString('ru-RU', { weekday: 'short' })}
                                        </div>

                                        <div className={`price-near ${price === lowestPrice ? 'best-price' : ''} ${isSelected ? names.text.accentNegative : names.text.primary}`}>
                                            {price && price > 0 ? 'от ' + price.toLocaleString() + ' ₽' : nonPrice}
                                            {(price === lowestPrice && price > 0) && (
                                                <div className={`best-price-badge ${names.backgrond.button.accent} ${names.text.negative}`}>Лучшая цена</div>
                                            )}
                                        </div>
                                    </div>
                                );
                            })}
                            <button className={"near-sliding near-sliding-right"} onClick={handleAddDay}>
                                <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.49981 6.20007L1.79981 0.600073C1.39981 0.200073 0.799805 0.200073 0.399805 0.600073C-0.00019478 1.00007 -0.000194833 1.60007 0.399805 2.00007L5.29981 7.00007L0.399804 12.0001C-0.000195777 12.4001 -0.000195829 13.0001 0.399804 13.4001C0.599804 13.6001 0.799805 13.7001 1.0998 13.7001C1.3998 13.7001 1.5998 13.6001 1.7998 13.4001L7.49981 7.80007C7.89981 7.30007 7.89981 6.70007 7.49981 6.20007C7.49981 6.30007 7.49981 6.30007 7.49981 6.20007V6.20007Z"
                                        fill={theme.icon.tertiary} />
                                </svg>
                            </button>
                        </div>
                    </div>}

            </div>
        );
    }
};




export default ContainerNear;
