import React, { useState, useRef } from "react";
import useOnClickOutside from "../hooks";
import Select from "../select";
import { names } from "../../Styles/names";

function GuestSelect(props) {
  const ref = useRef();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  useOnClickOutside(ref, () => setDropdownOpen(false));

  return (
    <div
      className={`search-form__input-guest${isDropdownOpen ? " active " + names.backgrond.button.primaryLight : ""}`}
      onClick={() => setDropdownOpen(true)}
      ref={ref}
    >
      <div className={`title ${names.text.tertiaryDark}`}>Гости</div>
      <div className={`content ${names.text.secondary}`}>
        {props.adult} {props.adult > 1 ? "взрослых" : "взрослый"}
        {(props.child.length === 1 && props.child[0] != '')
          ? ", 1 ребенок"
          : props.child.length >= 2 && props.child.length <=4
          ? `, ${props.child.length} ребенка`
          : props.child.length > 4
          ? `, ${props.child.length} детей`
          : ", без детей"}
      </div>
      <div className="dropdown-wrapper__guest">
        <div className="adults-wrapper">
          <div className={`adults__title ${names.text.tertiaryDark}`}>Взрослые</div>
          <div className="adults-counter">
            <button
              className={`adults-counter__minus ${names.backgrond.button.primaryLight} ${names.text.accentNegative}`}
              onClick={() =>
                props.onAdultChange(
                  props.adult > 1 ? props.adult - 1 : props.adult
                )
              }
            >
              -
            </button>
            <div className={`adults-counter__count ${names.text.primary}`}>{props.adult}</div>
            <button
              className={`adults-counter__plus ${names.backgrond.button.primaryLight} ${names.text.accentNegative}`}
              onClick={() =>
                props.onAdultChange(
                  props.adult < 30 ? props.adult + 1 : props.adult
                )
              }
            >
              +
            </button>
          </div>
        </div>
        <div className="childs-wrapper">
          <div className={`childs__title ${names.text.tertiaryDark}`}>Дети</div>
          <Select
            childCount={props.child}
            ageOptions={props.ageOptions}
            onChildChange={(id, val) => props.onChildChange(id, val)}
            onChildRemove={(idx) => props.onChildRemove(idx)}
          />
        </div>
      </div>
    </div>
  );
}

export default GuestSelect;
