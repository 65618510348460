import React, {useState} from "react";
import moment from "moment";
import Slider from "react-slick";
import HotelRateMobile from "../HotelRate/HotelRateMobile";
import "./style-modal.css";
import { names } from "../../Styles/names";

function HotelModal(props) {
    const [isDescOpen, setDescOpen] = useState(false);

    if (!props.result.gallery_images) return <></>;

    const diff = moment(props.result.date_out).diff(
        moment(props.result.date_in),
        "days"
    );

    const rateSort = props.result.rate_group.sort((a, b) => {
        if (a.promo !== null && b.promo === null) {
            return -1; // a с promo должен быть первым
        } else if (a.promo === null && b.promo !== null) {
            return 1; // b с promo должен быть первым
        } else if (a.quotas_vacant === 0 && b.quotas_vacant !== 0) {
            return 1; // b без own_quotas должен быть первым
        } else if (a.quotas_vacant !== 0 && b.quotas_vacant === 0) {
            return -1; // a без own_quotas должен быть первым
        } else {
            return a.order_rate - b.order_rate; // оба элемента имеют или не имеют promo, сортируем по order_rate
        }
    });

    let childrenInfo = ''
    if (props.result.children_info.quantity === 1)
        childrenInfo = ", 1 ребенок"
    else if (props.result.children_info.quantity <= 4 && props.result.children_info.quantity > 0)
        childrenInfo = `, ${props.result.children_info.quantity} ребенка`
    else if (props.result.children_info.quantity > 4)
        childrenInfo = `, ${props.result.children_info.quantity} детей`

    const guestsInfo = props.result.adults + " " + (props.result.adults > 1 ? "взрослых" : "взрослый") + childrenInfo

    const hotelRates = rateSort.map((rate, idx) => {
            return (
                <HotelRateMobile
                    rate={{
                        id: rate.rate_id,
                        name: rate.rate_name,
                        promo: rate.promo,
                        description: rate.rate_description,
                        background: rate.rate_bg,
                        price: Math.round(rate.rate_price),
                        prices: rate.prices,
                        services: rate.rate_services,
                        food: rate.food,
                        max_stay: rate.max_stay,
                        multiple_days: rate.multiple_days,
                        extraBed: rate.extraBed,
                        craddle: rate.craddle,
                        related_rate: rate.related_rate,
                        dateIn: rate.dateIn,
                        dateOut: rate.dateOut,
                        discount: rate.discount,
                        tariff_characteristics: rate.tariff_characteristics,
                        room_rates: rate.room_rates,
                        promo_id: rate?.promo_id,
                        total_discount: rate?.total_discount,
                        tariff_after: rate?.tariff_after,
                        tariff_before: rate?.tariff_before,
                        loyalty: rate.loyalty,
                        payment_methods_oob: rate.payment_methods_oob,
                        stocks: rate.stocks,
                    }}
                    amount={rate.quotas_vacant}
                    own_quotas={rate.own_quotas}
                    room_type_id={props.result.room_type_id}
                    room_type_name={props.result.room_type_name}
                    total={Math.round(props.result.total)}
                    date_in={props.result.date_in}
                    date_out={props.result.date_out}
                    diff={diff}
                    hotelId={props.hotelId}
                    productId={props.productId}
                    adults={props.result.adults}
                    children={props.result.children}
                    children_info={props.result.children_info}
                    babies={props.result.babies}
                    rooms={props.result.rooms}
                    image={props.result.image}
                    onModalOpen={props.onModalOpen}
                    onModalClose={props.onModalClose}
                    key={idx}
                    extraPlace={rate.extraPlace}
                    cradlePlace={props.result.cradlePlace}
                    guestsInfo={guestsInfo}
                    upsaleProduct={props.upsaleProduct}
                    syncMindBox={props.syncMindBox}
                />
            )
        }
    )

    return (
        <div className={`modal-hotel ${names.backgrond.primary}${props.modal ? " show" : ""}`}>
            <div className="modal-header">
                <button
                    className={names.text.secondary}
                    onClick={() => props.onModalShowChange(false)}
                >
                    <i className="icon icon-arrow-left"></i> Все номера
                </button>
                <a className={`active ${names.text.accentNegative}`} href="#select_tarif">
                    Выбор тарифа <i className="icon icon-arrow-right"></i>
                </a>
            </div>
            <div className="modal-body">
                <Slider
                    {...{
                        dots: true,
                        arrows: false,
                        speed: 500,
                        infinite: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }}
                >
                    {props.result.gallery_images.map((image, idx) => (
                        <img key={idx} src={process.env.REACT_APP_API_URL_DIRECTLY + image.link} alt={props.result.room_type_name}/>
                    ))}
                </Slider>
                <div className="modal-hotel-content">
                    <div className="hotel-content__title">
                        <button className={names.text.secondary}>{props.result.room_type_name}</button>
                    </div>
                    <div
                        className={`hotel-content__last-number${
                            props.result.room_type_vacant > 0 && props.result.room_type_vacant < 5 ? "" : " ur-d-none"
                        }`}
                    >
                        {/*{props.result.room_type_vacant <= 0 && "Нет свободных номеров"}*/}
                        {props.result.room_type_vacant === 1 && "Остался всего 1 номер"}
                        {props.result.room_type_vacant >= 2 &&
                            "Осталось всего " + props.result.room_type_vacant + " номера"}
                    </div>
                    <div className="hotel-content__options">
                        <div className={`options-item ${names.text.secondary}`}>
                            <i className="icon icon-guest"></i> до{" "}
                            {props.result.room_type_capacity} человек
                        </div>
                        <div className={`options-item ${names.text.secondary}`}>
                            <i className="icon icon-area"></i>
                            {props.result.room_type_square[0] ===
                            props.result.room_type_square[1] ? (
                                <>{props.result.room_type_square[0]}м²</>
                            ) : (
                                <>
                                    {props.result.room_type_square[0]}м²
                                    {" - "}
                                    {props.result.room_type_square[1]}м²
                                </>
                            )}
                        </div>
                    </div>

                    <div
                        className={`modal-hotel-room__descritpion${
                            isDescOpen ? " show" : ""
                        }`}
                    >
                        <div
                            className={`hotel-content__description ${names.text.secondary}`}
                            dangerouslySetInnerHTML={{
                                __html: props.result.room_type_description.replace(
                                    /<\/?[^>]+>/gi,
                                    ""
                                ),
                            }}
                        ></div>


                    </div>
                    <button
                        className={[
                            "ur-btn ur-btn-secondary",
                            names.text.secondary,
                            names.border.tertiary,
                            names.backgrond.button.tertiary
                        ].join(' ')}
                        onClick={() => setDescOpen(!isDescOpen)}
                    >
                        {!isDescOpen ? "Полное описание номера" : "Скрыть описание номера"}
                    </button>
                </div>
                <div className="modal-hotel-rates" id="select_tarif">
                    <div className={`h3 ${names.text.secondary}`}>Выберите Тариф:</div>
                    {hotelRates}
                </div>
            </div>
        </div>
    );
}

export default HotelModal;
