import React from "react";
import ReactDOM from "react-dom/client";
import HotelIcon from "./fonts/fonts";
import "./index.css";
import ReservationContainer from "./ReservationContainer";
import * as serviceWorker from "./serviceWorker";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Styles } from "./Styles";


console.log('Is loaded set to false!');

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

root.render(
    <>
        <Styles />
        <ToastContainer />
        <HotelIcon />
        <ReservationContainer />
    </>
);

serviceWorker.unregister();
