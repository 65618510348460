import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import Popup from "reactjs-popup";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "reactjs-popup/dist/index.css";
import "./style-mobile.css";
import Slider from "react-slick";
import { fetchAddHotelToCart } from "../functions";
import { names } from "../../Styles/names";
import theme from "../../Styles/theme.json";

function HotelRateMobile(props) {
    const [loader, setLoader] = useState(false);
    const [isRateOpen, setRateOpen] = useState(false);

    const customTooltip = {
        background: theme.background.negative,
        borderRadius: "16px",
        color: theme.text.negative,
        borderColor: theme.background.negative
    }

    const getFormatedText = (text) => {
        if (text) return text.split("\n").map((str, i) => <p key={i} dangerouslySetInnerHTML={{ __html: str }} />);
    };
    const strip_tags = (str) => {
        if (str) {
            str = str.toString();
            return str.replace(/<\/?[^>]+>/gi, "");
        }
    };
    let bgClass = "";
    let bgStyle = {};
    if (props.rate.background) {
        bgStyle.backgroundImage =
            "linear-gradient(0deg, rgba(72, 65, 81, .6), rgba(72, 65, 81, .6)), url('" +
            process.env.PUBLIC_URL +
            props.rate.background +
            "')";
        bgClass = " has-bg";
    }

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2,
        },
    };

    const carouselStyle = {
        width: "calc(100% - 8px)",
        height: "104px",
        marginLeft: "4px",
        marginRight: "4px",
    };

    const combined = []
    let servicesAll = props.rate.services

    if (props.rate?.tariff_before) {
        servicesAll = servicesAll.concat(props.rate.tariff_before.services)
        combined.push({ before: props.rate.tariff_before })
    }

    if (props.rate?.tariff_after) {
        servicesAll = servicesAll.concat(props.rate.tariff_after.services)
        combined.push({ after: props.rate.tariff_after })
    }

    servicesAll = servicesAll.reduce((acc, curr) => {
        const isDuplicate = acc.some(item => item.id === curr.id);
        if (!isDuplicate) {
            acc.push(curr);
        }
        return acc;
    }, []);

    function declOfNum(number, titles) {
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[
            number % 100 > 4 && number % 100 < 20
                ? 2
                : cases[number % 10 < 5 ? number % 10 : 5]
        ];
    }

    function rateOpen(e) {
        e.preventDefault();
        setRateOpen(!isRateOpen);
    }

    const getNdsServices = (services) => {
        const nds_services = [];
        services.forEach((e) => {
            if (e.nds !== 0) {
                let el = {};
                el.name = e.name;
                el.price = e.price;
                el.nds = e.nds;
                el.included = e.included;
                nds_services.push(el);
            }
        });
        return nds_services;
    };

    const UpSaleCard = ({ product, onReject, onSubmit, extraBed = null, craddle = null }) => {
        const [isShort, setShort] = useState(false)
        const [isLoading, setLoading] = useState(false)
        const headerRef = useRef(null)
        const descRef = useRef(null)
        const lastButtonRef = useRef(null)
        useEffect(() => {
            const descriptionEl = descRef.current
            const lastButtonEl = lastButtonRef.current
            const wrapper = headerRef.current.closest('.ReactModal__Content')
            if (wrapper) {
                wrapper.scrollTop = 0
                lastButtonEl.scrollIntoView({ behavior: "smooth" })
            }
            if (descriptionEl.offsetHeight > 60) {
                setShort(true)
            }
        }, [])
        const upRate = product.rate_group.find(rate => rate.rate_id === props.rate.id) ?? product.rate_group[0]
        const newPrice = upRate.rate_price / upRate.prices.length - props.rate.price / props.rate.prices.length

        const loader = isLoading ? (
            <span
                className="ur-spinner-border ur-spinner-border-sm ur-mr-1 ur-spinner-border-white"
                role="status"
                aria-hidden="true"
            ></span>
        ) : (
            ""
        );
        const handleReject = () => {
            if (onReject) onReject()
        }
        const submitData = () => {
            if (!isLoading) {
                setLoading(true)
                if (onSubmit) onSubmit()
                const craddleSend = craddle ?? props.rate.craddle
                const extraBedSend = extraBed ?? props.rate.extraBed
                const roomRatesSend = upRate.room_rates

                if (process.env.REACT_APP_DATA_FORM_SEND === 'true') {
                    const objCartForm = {
                        dates: `${moment(upRate.dateIn).format("DD-MM-YYYY")} - ${moment(upRate.dateOut).format("DD-MM-YYYY")}`,
                        promo: upRate.promo,
                        adults: props.adults,
                        children: props.children,
                        babies: props.babies,
                        rooms: 1,
                        rate_id: upRate.rate_id,
                        room_type_id: product.room_type_id,
                        rate_name: upRate.rate_name,
                        room_type_name: product.room_type_name,
                        product_id: props.productId,
                        hotel_id: props.hotelId,
                        food: upRate.food,
                        children_info: JSON.stringify(props.children_info),
                        craddle: craddleSend,
                        extraBed: extraBedSend,
                        tariff_type: upRate.tariff_characteristics,
                        room_rates: roomRatesSend,
                        promo_id: upRate?.promo_id,
                        combined: combined,
                        nds_services: JSON.stringify(getNdsServices(upRate.rate_services)),
                        redirect: "cart"
                    };

                    const form = document.createElement("form");
                    form.method = "POST";
                    form.action = `${process.env.REACT_APP_API_URL}index.php?route=checkout/cart/add`;
                    form.style.display = "none";

                    Object.entries(objCartForm).forEach(([key, value]) => {
                        const input = document.createElement("input");
                        input.type = "hidden";
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    });

                    const inputSub = document.createElement("input");
                    inputSub.type = "submit";
                    form.appendChild(inputSub);

                    document.body.appendChild(form);
                    form.submit();

                    return;
                }

                let formData = new FormData();
                formData.append(
                    "dates",
                    moment(props.date_in).format("DD-MM-YYYY") +
                    " - " +
                    moment(props.date_out).format("DD-MM-YYYY")
                );
                formData.append("promo", upRate.promo);
                formData.append("adults", props.adults);
                formData.append("children", props.children);
                formData.append("babies", props.babies);
                formData.append("rooms", 1);
                formData.append("rate_id", upRate.rate_id);
                formData.append("room_type_id", product.room_type_id);
                formData.append("rate_name", upRate.rate_name);
                formData.append("room_type_name", product.room_type_name);
                formData.append("product_id", props.productId);
                formData.append("hotel_id", props.hotelId);
                formData.append("food", upRate.food);
                formData.append("children_info", JSON.stringify(props.children_info));
                formData.append("craddle", craddleSend);
                formData.append("extraBed", extraBedSend);
                formData.append("tariff_type", upRate.tariff_characteristics);
                formData.append("room_rates", roomRatesSend);
                formData.append("promo_id", upRate?.promo_id);
                formData.append("nds_services", JSON.stringify(getNdsServices(upRate.rate_services)))
                formData.append("combined", combined);

                fetchAddHotelToCart(props.productId, formData)
                    .finally(() => {
                        setLoading(false);
                        props.onModalClose();
                    });
            }
        }

        return (
            <>
                <p className={`mobile-modal__hedaer text-snoops ${names.text.primary}`} ref={headerRef}>
                    Повысить комфорт?
                </p>
                <div className="mobile-modal__content upsale-mobile">
                    <div className="upsale-mobile__gallery">
                        <Slider
                            {...{
                                dots: true,
                                arrows: false,
                                speed: 500,
                                infinite: false,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                            }}
                        >
                            {product.gallery_images.map((image, idx) => (
                                <img
                                    key={idx}
                                    src={process.env.REACT_APP_API_URL_DIRECTLY + image.link}
                                    alt={product.room_type_name}
                                />
                            ))}
                        </Slider>
                    </div>
                    <div className="upsale-mobile__content">
                        {product.quotas_vacant < 4 && product.quotas_vacant > 0 &&
                            <p className="upsale-mobile__last-rooms">
                                {declOfNum(product.quotas_vacant, ['Остался', 'Осталось', 'Осталось'])} всего {product.quotas_vacant} {declOfNum(product.quotas_vacant, ['номер', 'номера', 'номеров'])}
                            </p>
                        }
                        <p className={`upsale-mobile__title ${names.text.primary}`}>{product.room_type_name}</p>
                        <div
                            className={`upsale-mobile__description ${names.text.primary}${isShort ? ' short' : ''}`}
                            ref={descRef}
                            dangerouslySetInnerHTML={{
                                __html: product.room_type_description.replace(
                                    /<\/?[^>]+>/gi,
                                    ""
                                ),
                            }}
                        ></div>
                        <button
                            className={`"upsale-mobile__read-more ${names.text.tertiary}`}
                            style={!isShort ? { display: 'none' } : undefined}
                            onClick={() => setShort(false)}
                        >Читать полностью</button>
                        <div className="upsale-mobile__props flex">
                            <p className={`upsale-mobile__prop ${names.backgrond.secondary} ${names.text.secondary}`}>
                                <span className={`hotel-icon icon-guest ${names.text.tertiaryDark}`}></span> до {product.room_type_capacity} {declOfNum(product.room_type_capacity, ['человека', 'человек', 'человек'])}
                            </p>
                            <p className={`upsale-mobile__prop ${names.backgrond.secondary} ${names.text.secondary}`}>
                                <span className={`hotel-icon icon-guest ${names.text.tertiaryDark}`}></span>Площадь {product.room_type_square[0]} м²
                            </p>
                        </div>
                    </div>
                    <div className="upsale-mobile__bottom">
                        <p className={`upsale-mobile__extra-charge ${names.text.primary}`}>
                            Всего <span className={names.text.accent}>+{newPrice.toLocaleString()} Р</span> за 1 ночь
                        </p>
                        <button
                            className={`upsale-mobile__resolve ${names.backgrond.button.negative} ${names.text.negative}`}
                            onClick={submitData}
                            style={isLoading ? { color: 'transparent', position: 'relative' } : undefined}
                        >
                            {loader}Да, поменять номер
                        </button>
                        <button ref={lastButtonRef} className={`upsale-mobile__reject ${names.text.tertiary}`} onClick={handleReject}>Спасибо, не надо</button>
                    </div>
                </div>
            </>
        )
    }

    function addCartItem(extraBed = null, craddle = null, roomRates = null, price = 0) {

        const maxStay = props.rate.max_stay;
        const multipleDays = props.rate.multiple_days;
        const diff = props.diff;


        if (maxStay && diff > maxStay) {
            props.onModalOpen(true, "Внимание!", "Выберите период проживания не более " +
                maxStay +
                props.declOfNum(maxStay, [" ночь.", " ночи.", " ночей."]));

        } else {
            const submitData = () => {
                setLoader(true);

                const craddleSend = craddle ?? props.rate.craddle
                const extraBedSend = extraBed ?? props.rate.extraBed
                const roomRatesSend = roomRates ?? props.rate.room_rates

                if (process.env.REACT_APP_DATA_FORM_SEND === 'true') {
                    const objCartForm = {
                        dates: `${moment(props.rate.dateIn).format("DD-MM-YYYY")} - ${moment(props.rate.dateOut).format("DD-MM-YYYY")}`,
                        promo: props.rate.promo,
                        adults: props.adults,
                        children: props.children,
                        babies: props.babies,
                        rooms: 1,
                        rate_id: props.rate.id,
                        room_type_id: props.room_type_id,
                        rate_name: props.rate.name,
                        room_type_name: props.room_type_name,
                        product_id: props.productId,
                        hotel_id: props.hotelId,
                        food: props.food,
                        children_info: JSON.stringify(props.children_info),
                        craddle: craddleSend,
                        extraBed: extraBedSend,
                        tariff_type: props.rate.tariff_characteristics,
                        room_rates: roomRatesSend,
                        promo_id: props.rate?.promo_id,
                        combined: combined,
                        nds_services: JSON.stringify(getNdsServices(props.rate.services)),
                        redirect: "cart"
                    };

                    const form = document.createElement("form");
                    form.method = "POST";
                    form.action = `${process.env.REACT_APP_API_URL}index.php?route=checkout/cart/add`;
                    form.style.display = "none";

                    Object.entries(objCartForm).forEach(([key, value]) => {
                        const input = document.createElement("input");
                        input.type = "hidden";
                        input.name = key;
                        input.value = value;
                        form.appendChild(input);
                    });

                    const inputSub = document.createElement("input");
                    inputSub.type = "submit";
                    form.appendChild(inputSub);

                    document.body.appendChild(form);
                    form.submit();

                    return;
                }

                let formData = new FormData();
                formData.append(
                    "dates",
                    moment(props.date_in).format("DD-MM-YYYY") +
                    " - " +
                    moment(props.date_out).format("DD-MM-YYYY")
                );
                formData.append("promo", props.rate.promo);
                formData.append("adults", props.adults);
                formData.append("children", props.children);
                formData.append("babies", props.babies);
                formData.append("rooms", 1);
                formData.append("rate_id", props.rate.id);
                formData.append("room_type_id", props.room_type_id);
                formData.append("rate_name", props.rate.name);
                formData.append("room_type_name", props.room_type_name);
                formData.append("product_id", props.productId);
                formData.append("hotel_id", props.hotelId);
                formData.append("food", props.rate.food);
                formData.append("children_info", JSON.stringify(props.children_info));
                formData.append("craddle", craddleSend);
                formData.append("extraBed", extraBedSend);
                formData.append("tariff_type", props.rate.tariff_characteristics);
                formData.append("room_rates", roomRatesSend);
                formData.append("promo_id", props.rate?.promo_id);
                formData.append("nds_services", JSON.stringify(getNdsServices(props.rate.services)))
                formData.append("combined", combined);
                formData.append("payment_methods_oob", JSON.stringify(props.rate.payment_methods_oob));

                props.syncMindBox(props.productId, 1, price)

                fetchAddHotelToCart(props.productId, formData)
                    .finally(() => {
                        setLoader(false);
                    });
            }
            if (props.upsaleProduct) {
                const handleReject = () => {
                    props.onModalClose()
                    submitData()
                }
                props.onModalOpen(true, "", <UpSaleCard
                    onReject={() => { handleReject() }}
                    product={props.upsaleProduct}
                    extraBed={extraBed}
                    craddle={craddle}
                />, 'xs', 'mobile-modal modal__bk__mobile');
            } else submitData()
        }
    }

    const load = loader ? (
        <span
            class="ur-spinner-border ur-spinner-border-sm ur-mr-1 ur-spinner-border-white"
            role="status"
            aria-hidden="true"
        ></span>
    ) : (
        ""
    );

    const propsRate = [];
    propsRate.push(props.rate);
    if (props.rate?.tariff_before) propsRate.push(props.rate.tariff_before);
    if (props.rate?.tariff_after) propsRate.push(props.rate.tariff_after);

    const [show, setShow] = useState(false);
    const [idNumber, setIdNumber] = useState(0);
    useEffect(() => {
        if (propsRate.length > 1)
            props.onModalOpen(show, "Описание:", getRateModalBody(propsRate));

        setShow(true);
    }, [idNumber]);

    const tabsHandler = (e) => {
        const id = Number(e.target.id.replace("buttonId", ""));
        const toggleId = id === 0 ? 1 : 0;
        setIdNumber(id);

        const buttons = document.querySelectorAll(".pressButton");
        buttons.forEach((button) => {
            button.classList.remove("pressButton");
        });

        const button = document.getElementById("buttonId" + id);
        button.classList.add("pressButton");
    };

    let mainPrice = props.rate.price;

    const getHTMLForServices = (data) => {
        return (
            <div dangerouslySetInnerHTML={{ __html: data }} />
        )
    }

    const getRateModalBody = (rates) => {
        const buttons = rates.map((r, i) => {
            return (
                <button
                    className={i === 0 ? "pressButton" : ""}
                    onClick={(e) => tabsHandler(e)}
                    style={{ fontWeight: "700", margin: "30px 20px 20px" }}
                    key={"buttonId" + i}
                    id={"buttonId" + i}
                >
                    {r.name}
                </button>
            );
        });

        const services = rates[idNumber].services.map((name, idx) => {
            if (name.price > 0 && name.totalServices == 0) return; // Пропускаем платные услуги, недосупные текущей группе.
            return (
                <div
                    key={idx}
                    className={`services-item ${names.border.secondaryLight}`}
                    style={{ flex: "0 0 calc(25% - 13px)", marginBottom: "12px" }}
                >
                    <i className={`icon ${name.icon} ${names.text.accentNegative}`}></i>
                    <p className={names.text.secondary}>{name.name}</p>
                </div>
            );
        });

        return (
            <React.Fragment>
                <img
                    src={process.env.PUBLIC_URL + rates[0].background}
                    style={{
                        width: "calc(100% + 40px)",
                        marginLeft: "-20px",
                        marginRight: "-20px",
                        marginTop: "-10px",
                        ...(!rates[0].background && { display: "none" }),
                    }}
                    alt=""
                />
                {buttons}
                <p
                    style={{
                        fontWeight: "500",
                        color: "#929292",
                        marginTop: "16px",
                        marginBottom: "16px",
                    }}
                >
                    Тариф включает:
                </p>
                <div
                    className="hotel-content__services"
                    style={{
                        display: "flex",
                        width: "calc(100% + 16px)",
                        flexWrap: "wrap",
                    }}
                >
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={false}
                    >
                        {services}
                    </Carousel>
                </div>
                <div
                    dangerouslySetInnerHTML={{
                        __html: rates[idNumber].description,
                    }}
                />
            </React.Fragment>
        );
    };

    const getPricesArr = (prices) => {
        let pricesArr = JSON.parse(JSON.stringify(prices))
        servicesAll.forEach(
            ({ calculation_mode, price, services_days, child_price, name }) => {  // пробегаемся по всем услугам

                services_days.forEach((date) => { // пробегаемся по каждой дате для котороя применима услуга

                    pricesArr = pricesArr.map((value) => { // пробегаемся по всем ценам
                        if (!value) return
                        if (value.date !== date) return value

                        switch (calculation_mode) {
                            case "per-usage":
                                const indexUsage = pricesArr.findIndex((value) => value.date === date);
                                pricesArr[indexUsage].price += price;
                                pricesArr[indexUsage].costService += price;
                                pricesArr[indexUsage].detail = price > 0 ? (pricesArr[0].detail == undefined ? name : pricesArr[0].detail + "<br>" + name) : "";
                                break;
                            case "per-person":
                                return {
                                    ...value,
                                    price: value.date == date ? (value.price + price * props.adults + child_price * (props.children + props.babies)) : value.price,
                                    detail: value.date == date && (price * props.adults + child_price * (props.children + props.babies)) > 0 ? (value.detail == undefined ? name : value.detail + "<br>" + name) : value.detail,
                                    costService: value.date == date && price != 0 ? (value.costService ?? 0) + (price * props.adults + child_price * (props.children + props.babies)) : value.costService
                                }
                            case "per-room":
                                return {
                                    ...value,
                                    price: value.date == date ? value.price + price : value.price,
                                    detail: value.date == date && price > 0 ? (value.detail == undefined ? name : value.detail + "<br>" + name) : value.detail,
                                    costService: value.date == date && price != 0 ? (value.costService ?? 0) + price : value.costService
                                };
                            case "per-guest":
                                const indexGuest = pricesArr.findIndex((value) => value.date === date);
                                pricesArr[indexGuest].price += price * props.adults + price * (props.children + props.babies);
                                pricesArr[indexGuest].costService += price * props.adults + price * (props.children + props.babies);
                                pricesArr[indexGuest].detail = (price * props.adults + price * (props.children + props.babies)) > 0 ? (pricesArr[0].detail == undefined ? name : pricesArr[0].detail + "<br>" + name) : "";
                                break;
                            case "per-adult":
                                return {
                                    ...value,
                                    price: value.date == date ? value.price + price * props.adults : value.price,
                                    costService: value.date == date && price != 0 ? (value.costService ?? 0) + price * props.adults : value.costService,
                                    detail: value.date == date && (price * props.adults) > 0 ? (value.detail == undefined ? name : value.detail + "<br>" + name) : value.detail,
                                };
                            case "per-adultED":
                                return {
                                    ...value,
                                    price: value.date == date ? value.price + price * props.adults : value.price,
                                    costService: value.date == date && price != 0 ? (value.costService ?? 0) + price * props.adults : value.costService,
                                    detail: value.date == date && (price * props.adults) > 0 ? (value.detail == undefined ? name : value.detail + "<br>" + name) : value.detail,
                                };
                            case "per-childrenED":
                                return {
                                    ...value,
                                    price: value.date == date ? value.price + price * props.children : value.price,
                                    costService: value.date == date && price != 0 ? (value.costService ?? 0) + price * props.children : value.costService,
                                    detail: value.date == date && (price * props.children) > 0 ? (value.detail == undefined ? name : value.detail + "<br>" + name) : value.detail,
                                };
                            default:
                                break;
                        }
                        return value;
                    });
                });
            });
        return pricesArr;
    }

    let showOldPrice = false
    let priceFinal = Math.round(mainPrice)
    if (props.rate.total_discount.price !== 0) {
        priceFinal = Math.round(props.rate.total_discount.price)
        showOldPrice = true
    }
    const sale = props.amount > 0 //Наличие квот

    const bonusContainer =
        <div className='bonus-container'>
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd"
                    d="M9.37321 1.75788L1.85522 9.27964C1.56822 9.56679 1.10622 9.56679 0.826215 9.27964H0.819215C-0.153785 8.29915 -0.153785 6.71636 0.819215 5.74287L5.82421 0.735368C6.80421 -0.245123 8.38621 -0.245123 9.36621 0.735368C9.65321 1.0085 9.65321 1.47774 9.37321 1.75788ZM14 13.4747C14 13.7619 13.762 14 13.475 14H0.525C0.238 14 0 13.7619 0 13.4747C0 13.1876 0.238 12.9495 0.525 12.9495H4.48075L2.49223 10.9534C2.20523 10.6663 2.20523 10.2041 2.49223 9.92392L10.0102 2.40215C10.2902 2.11501 10.7522 2.11501 11.0392 2.40215L13.1742 4.53822C14.1612 5.51871 14.1612 7.10851 13.1672 8.089L8.31598 12.9495H13.475C13.762 12.9495 14 13.1876 14 13.4747ZM6.68523 11.7168L7.53223 10.8624C7.70723 10.6943 7.70723 10.3932 7.53223 10.2181C7.35723 10.05 7.06323 10.05 6.89523 10.2181L6.04123 11.0725C5.86623 11.2546 5.86623 11.5417 6.04123 11.7168C6.22323 11.8919 6.51023 11.8919 6.68523 11.7168ZM8.60323 9.79785L10.3112 8.082C10.4862 7.90691 10.4862 7.61976 10.3112 7.43767C10.1362 7.26959 9.84223 7.26959 9.67423 7.43767L7.95923 9.15353C7.78423 9.33562 7.78423 9.62277 7.95923 9.79785C8.14123 9.96594 8.42823 9.96594 8.60323 9.79785Z"
                    fill={theme.text.secondary} />
            </svg>
            <Popup
                on={["hover", "focus"]}
                position="top center"
                contentStyle={customTooltip}
                trigger={<span className={[names.text.secondary, names.border.primary]}>
                    {/*Начислим от {getValueBonuses(priceFinal, props.rate?.tariff_characteristics)} баллов*/}
                    Как получить скидку или кешбэк
                </span>}
                arrowStyle={{
                    color: theme.background.negative,
                    stroke: theme.background.negative
                }}
            >
                <div className="custom-tooltip">
                    <div>
                        {/*Баллы можно тратить на Яндекс Путешествиях и в других сервисах Яндекса.{' '}*/}
                        Зарегистрируйтесь в программе лояльности Курорта Красная Поляна и в зависимости от тарифа получите кешбэк при бронирования отеля до 7% или скидку на проживание до 12%.
                        <a className="link-bonus-popup" href="https://krasnayapolyanaresort.ru/loyalty">Подробнее...</a>
                    </div>
                </div>
            </Popup>
        </div>

    const getHotelPrice = (extraPlace = null, cradlePlace = null, noAlternative = false) => {
        let prices = props.rate.prices
        let extraBed = null
        let craddle = null
        let roomRates = null


        if (extraPlace !== null) {
            prices = extraPlace.prices
            mainPrice = Math.round(extraPlace.amount)
            priceFinal = Math.round(extraPlace.amount)
            extraBed = true
            roomRates = extraPlace.room_rates
        } else if (props.rate.extraBed) {
            extraBed = true
        }

        if (cradlePlace !== null) {
            prices = cradlePlace.prices
            mainPrice = Math.round(extraPlace.amount)
            priceFinal = Math.round(extraPlace.amount)
            craddle = true
            roomRates = cradlePlace.room_rates
        } else if (props.rate.craddle) {
            craddle = true
        }

        const pricesArr = getPricesArr(prices)

        return (
            <>
                {((noAlternative && (props.children || props.babies)) || !noAlternative) &&
                    <div className="guests-info-text-in-rate">
                        <p className={`subtitle guests-info-mobile ${names.text.tertiaryDark}`}>
                            {props.guestsInfo}
                        </p>
                        <p className={`subtitle guests-info-mobile ${names.text.tertiaryDark}`}>
                            {extraBed ? " + доп. место " : " "}{" "}
                            {craddle ? " + колыбель " : " "}
                            {!extraBed && !craddle ? " без доп. места " : " "}
                        </p>
                    </div>}
                <div className="hotel-price">
                    {/*<div className="hotel-price__col">*/}
                    {/*  <i className="icon icon-guest"></i>*/}
                    {/*</div>*/}
                    {sale && (<Popup
                        trigger={
                            <div>
                                <div className="info-wrapper">
                                    <i className={`icon icon-info ${names.backgrond.button.primary} ${names.text.negative}`}></i>
                                </div>
                            </div>
                        }
                        position="top left"
                        on={["hover", "focus"]}
                        arrowStyle={{
                            color: theme.background.primary,
                            stroke: theme.border.accent
                        }}
                        contentStyle={{
                            backgroundColor: theme.background.primary,
                            borderColor: theme.border.accent,
                            color: theme.text.primary
                        }}
                    >
                        <div>
                            {sale && pricesArr.map((value, idx) => (
                                <div key={idx}>
                                    <strong>{value.date}: {getPriceInPopup(value)} ₽ </strong><br /> <p
                                        className="daily-price_description" dangerouslySetInnerHTML={{ __html: value.detail }} />
                                </div>
                            ))}
                        </div>
                    </Popup>)}
                    {props.amount == 0 && (<div style={{ height: '10px' }}></div>)}
                    <div className="hotel-price__col" style={{ with: '80px' }}>
                        <div className="col-discount ur-d-none">
                            <span className="price-percent">-5%</span>
                            <span className="price-discount">
                                {(props.rate.price + (props.rate.price / 100) * 5).toLocaleString()}{" "} ₽
                            </span>
                        </div>
                        {sale && (<div className="col-price col-price-mobile">
                            {showOldPrice && <>
                                <span
                                    className={names.text.secondary}
                                    style={{ textDecoration: "line-through", fontSize: "0.8em" }}
                                >
                                    {Math.round(mainPrice).toLocaleString()} ₽
                                </span>
                                <br />
                            </>}
                            {priceFinal.toLocaleString()} ₽
                        </div>)}
                    </div>

                    <div className="hotel-price__col">
                        {sale ? (
                            <button
                                className={`ur-btn ur-btn-buy ${names.backgrond.button.accent} ${names.text.negative}${loader ? " load" : ""}`}
                                disabled={loader}
                                onClick={() =>
                                    addCartItem(
                                        extraBed,
                                        craddle,
                                        roomRates,
                                        priceFinal
                                    )
                                }
                            >
                                {load}
                                Выбрать
                            </button>
                        ) : (
                                <div className={`ur-btn ur-btn-buy ${names.backgrond.button.tertiary} ${names.text.secondary}`}>
                                Распродано
                            </div>
                        )}
                    </div>
                </div>
                {props.rate.loyalty === 1 && bonusContainer}
            </>
        )
    }

    const dateArr = []

    const pushDateArr = (from, to, name) => {
        dateArr.push(
            "с " +
            moment(from).format("DD.MM.YYYY") +
            " по " +
            moment(to).format("DD.MM.YYYY") +
            " " + name
        )
    }

    if (props.rate?.tariff_before && props.rate?.tariff_after) {
        pushDateArr(
            props.rate.tariff_before.date_from,
            props.rate.tariff_before.date_to,
            props.rate.tariff_before.name
        )
        pushDateArr(
            props.rate.tariff_before.date_to,
            props.rate.tariff_after.date_from,
            props.rate.name
        )
        pushDateArr(
            props.rate.tariff_after.date_from,
            props.rate.tariff_after.date_to,
            props.rate.tariff_after.name
        )
    } else if (props.rate?.tariff_before) {
        pushDateArr(
            props.rate.tariff_before.date_from,
            props.rate.tariff_before.date_to,
            props.rate.tariff_before.name
        )
        pushDateArr(
            props.rate.tariff_before.date_to,
            props.rate.dateOut,
            props.rate.name
        )
    } else if (props.rate?.tariff_after) {
        pushDateArr(
            props.rate.dateIn,
            props.rate.tariff_after.date_from,
            props.rate.name
        )
        pushDateArr(
            props.rate.tariff_after.date_from,
            props.rate.tariff_after.date_to,
            props.rate.tariff_after.name
        )
    }

    return (
        <div
            className={`rate-description ${names.backgrond.primary} ${names.border.tertiaryDark}${bgClass}${isRateOpen ? " show" : ""}`}
            style={bgStyle}
        >
            <div className="shields-container-mobile">
                <div style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: '100%'
                }}>
                    {props?.rate?.stocks?.length && props?.rate?.stocks?.length > 0 ?
                        props.rate.stocks.map(stock => (
                            <span
                                className={names.text.negative}
                                key={stock.text}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    flexWrap: 'nowrap',
                                    borderRadius: '6px',
                                    margin: '5px',
                                    padding: '6px',
                                    backgroundColor: stock.color,
                                    // color: '#FFFFFF',
                                    fontWeight: 600,
                                    fontSize: '12px',
                                    lineHeight: '16px',
                                    textAlign: 'center'
                                }}
                            >
                            {stock.text}
                        </span>
                        ))
                        :
                        ''
                    }
                </div>
                {props.rate.promo !== null && (
                    <div className="hotel-content__promo shield">Промокод</div>
                )}
                {props.own_quotas == 1 &&
                    <div
                        className={`hotel-content__last-number${props.amount > 0 && props.amount < 5 ? "" : " ur-d-none"
                            } shield`}
                    >
                        {props.amount === 1 && "1 номер по тарифу"}
                        {props.amount > 1 && props.amount < 5 && (props.amount + " номера по тарифу")}
                        {props.amount === 5 && "5 номеров по тарифу"}
                    </div>}
            </div>
            <div className="ur-col-mobile">
                {dateArr.length > 0 ? (
                    <Popup
                        trigger={
                            <div className={`h4 ${names.text.primary}`} style={{ cursor: "help" }}>
                                Комбинированный тариф
                            </div>
                        }
                        on={["hover", "focus"]}
                    >
                        <div>
                            {dateArr.map((d, idx) => (
                                <div key={idx}>
                                    {d}
                                    <br />
                                    <br />
                                </div>
                            ))}
                        </div>
                    </Popup>
                ) : (
                        <div className={`h4 ${names.text.primary}`} style={{ marginBottom: "10px" }}>{props.rate.name}</div>
                )}
                {/*<p className="extra-bed-and-cradle">*/}
                {/*  {props.rate.extraBed ? "+ доп. кровать " : ""}{" "}*/}
                {/*  {props.rate.craddle ? "+ колыбель " : ""}*/}
                {/*</p>*/}
                {props.rate.description && dateArr.length === 0 &&
                    <div
                        className={names.text.secondary}
                        style={{ marginBottom: "15px" }}
                    >
                        {getFormatedText(
                            strip_tags(props.rate.description).substr(0, 50) + "..."
                        )}
                    </div>
                }
                <button
                    onClick={rateOpen}
                    className={`close${!isRateOpen ? " ur-d-none" : ""}`}
                >
                    <i className={`icon icon-arrow-up`}></i>
                </button>
            </div>
            <div
                className={`ur-col col-rate__footer${isRateOpen ? " ur-d-none" : ""}`}
            >
                <div className="col-discount ur-d-none">
                    <span className="price-percent">-5%</span>
                    <span className="price-discount ur-d-block">
                        {(props.rate.price + (props.rate.price / 100) * 5).toLocaleString()}{" "}
                        ₽
                    </span>
                </div>
                <div className="col-buy">
                    {showOldPrice && <>
                        <span style={{ textDecoration: "line-through", fontSize: "0.8em", color: "gray", margin: "inherit" }}>
                            {Math.round(mainPrice).toLocaleString()} ₽
                        </span>
                        <br />
                    </>}
                    <button
                        onClick={rateOpen}
                        className={
                            "ur-btn ur-btn-buy " +
                            (sale
                                ? `${names.backgrond.button.accent} ${names.text.negative}`
                                : `${names.backgrond.button.tertiary} ${names.text.secondary}`)
                        }
                    >
                        {(props.extraPlace || props.cradlePlace) && 'от '}
                        {sale && priceFinal.toLocaleString() + ' ₽'}
                        {props.amount == 0 && (<>Выбрать</>)}

                    </button>
                </div>
                {props.rate.loyalty === 1 && bonusContainer}
            </div>
            <div className={`ur-col${!isRateOpen ? " ur-d-none" : ""}`}>
                <p className={`subtitle ${names.text.tertiaryDark}`} style={{ marginBottom: "15px" }}>
                    Включено:
                </p>
                <div className="hotel-content__services">
                    <Carousel
                        responsive={responsive}
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        arrows={false}
                    >
                        {props.rate.services.map((item, idx) => {
                            if (item.price > 0 && item.totalServices == 0) return; // Пропускаем платные услуги, недосупные текущей группе.
                            return (
                                <div
                                    key={idx}
                                    className={`services-item ${names.border.secondaryLight}`}
                                    onClick={() =>
                                        props.onModalOpen(
                                            true,
                                            item.name,
                                            getFormatedText(item.description),
                                            "xs"
                                        )
                                    }
                                    style={carouselStyle}
                                >
                                    <i className={`icon ${item.icon} ${names.text.accentNegative}`}></i>
                                    <p className={names.text.secondary}>{item.name}</p>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
                <button
                    className={[
                        "ur-btn",
                        "ur-btn-secondary",
                        names.backgrond.button.tertiary,
                        names.border.tertiary,
                        names.text.secondary
                    ].join(' ')}
                    style={{ margin: "0 0 5px 0" }}
                    onClick={() =>
                        props.onModalOpen(
                            true,
                            'Описание:',
                            getRateModalBody(propsRate),
                            "xs", 'mobile-modal modal__bk__mobile'
                        )
                    }
                >
                    Полное описание тарифа
                </button>
            </div>
            <div className={`ur-col${!isRateOpen ? " ur-d-none" : ""}`}>
                {sale && (<p className={`subtitle ${names.text.tertiaryDark}`} style={{ margin: "15px 0 15px 0" }}>
                    Стоимость за {props.diff}{" "}
                    {declOfNum(props.diff, ["ночь", "ночи", "ночей"])}:

                </p>)}
                {getHotelPrice(null, null, !props.extraPlace && !props.cradlePlace)}
                {props.extraPlace && getHotelPrice(props.extraPlace)}
                {props.cradlePlace && getHotelPrice(null, props.cradlePlace)}
            </div>
        </div>
    );
}

export default HotelRateMobile;

const getValueBonuses = (price, variant) => {
    if (variant === 'special') {
        return (price * 0.01).toLocaleString()
    }
    if (variant === 'open') {
        return (price * 0.03).toLocaleString()
    }
    return
}

const getPriceInPopup = (value) => {
    if (value?.price_discount != 0) {
        return <>
            <span style={{ textDecoration: "line-through", fontSize: "0.8em", color: "gray" }}>
                {Math.round(value.price).toLocaleString()} ₽ {'  '}
            </span>
            {Math.round(value.price_discount + (value.costService ?? 0)).toLocaleString()}
        </>
    }
    return value.price
}
