import React from "react";
import Select, { components } from "react-select";
import theme from "../Styles/theme.json";

const colorStyles = {
  control: (styles) => {
    return {
      ...styles,
      backgroundColor: theme.background.primary,
      borderColor: theme.border.secondaryLight,
      "&:hover": { borderColor: theme.border.secondaryLight },
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
          ? theme.background.button.primaryLight
        : isFocused
            ? theme.background.button.primaryLight
        : null,
      color: theme.text.primary,
      cursor: "default",
      ":active": {
        ...styles[":active"],
        backgroundColor: theme.background.primary,
      },
    };
  },
  input: (styles) => ({ ...styles }),
  indicatorSeparator: (styles) => {
    return { ...styles, width: 0 };
  },
  dropdownIndicator: (styles) => {
    return { ...styles };
  },
  placeholder: (styles) => ({ ...styles, color: theme.text.accentNegative }),
  singleValue: (styles) => ({ ...styles, color: theme.text.accentNegative }),
  container: (styles) => {
    return {
      ...styles,
      display: "inline-block",
      minWidth: "200px",
      verticalAlign: "middle",
    };
  },
  menuList: (styles) => {
    return { ...styles, maxHeight: "185px" };
  },
  menu: (styles) => {
    return {
      ...styles,
      borderRadius: "0 0 8px 8px",
      marginTop: 0,
      boxShadow: "0 8px 24px rgba(0, 0, 0, .2)",
    };
  },
};

const dropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <div className="dropdown_indicator"></div>
    </components.DropdownIndicator>
  );
};

export default (props) => {
  const handleChange = (id, newValue) => {
    if (id === -1) {
      props.onChildChange(props.childCount.length, newValue);
    } else {
      props.onChildChange(id, newValue);
    }
  };

  const selects =  props.childCount.map((value, i) => (
    <div className="dropdown_item active" key={i}>
      <Select
        components={{ dropdownIndicator }}
        options={props.ageOptions}
        placeholder="Добавить ребенка"
        styles={colorStyles}
        value={props.ageOptions[value]}
        onChange={(e) => handleChange(i, e)}
      />
      <div
        className="search-form__guests-select-delete"
        onClick={() => props.onChildRemove(i)}
      ></div>
    </div>
  ));

  return (
    <div className="dropdown_wrapper">
      {selects}
      <div className="dropdown_item">
        <Select
          placeholder="Добавить ребенка"
          components={{ dropdownIndicator }}
          options={props.ageOptions}
          styles={colorStyles}
          value=""
          onChange={(e) => handleChange(-1, e)}
        />
      </div>
    </div>
  );
};
