import React, { useState, useRef } from "react";
import useOnClickOutside from "../hooks";
import { names } from "../../Styles/names";

function RoomSelect(props) {
  const ref = useRef();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const roomTypes = [{ id: 0, name: "Любой" }];
  Array.prototype.push.apply(roomTypes, props.roomTypes); 
  let roomTypeName = roomTypes.filter((item) => {
    if (item.id === props.roomTypeId) {
      return true;
    }
    return false;
  });
  roomTypeName = roomTypeName.length > 0 ? roomTypeName[0].name : roomTypes[0].name;

  const onRoomSelect = (newLabel, newValue) => {
    setDropdownOpen(false);
    props.onRoomTypeIdChange(newValue);
  };

  const select = roomTypes.map((val, idx) => (
    <div
      key={idx}
      className="dropdown-wrapper__item"
      onClick={() => onRoomSelect(val.name, val.id)}
    >
      {val.name}
    </div>
  ));

  useOnClickOutside(ref, () => setDropdownOpen(false));

  return (
    <div
      className={`search-form__input-number${isDropdownOpen ? " active " + names.backgrond.button.primaryLight : ""}`}
      ref={ref}
    >
      <div onClick={() => setDropdownOpen(true)}>
        <div className={`title ${names.text.tertiaryDark}`}>Номер</div>
        <div className={`content ${names.text.secondary}`}>{roomTypeName}</div>
      </div>
      <div className="dropdown-wrapper__number">
        <div className="dropdown-wrapper__list">{select}</div>
      </div>
    </div>
  );
}

export default RoomSelect;
