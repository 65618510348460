import { createGlobalStyle } from "styled-components";

import HotelIconWoff from "./Hotel-Icon.woff";

export default createGlobalStyle`
    @font-face {
        font-family: 'Hotel Icon';
        src: url(${HotelIconWoff}) format('woff');
        font-weight: 400;
        font-style: normal;
        font-display: swap;
    }
    [class^="icon-"], [class*=" icon-"] {
        /* use !important to prevent issues with browser extensions that change fonts */
        font-family: 'Hotel Icon' !important;
        speak: never;
        font-style: normal;
        font-weight: 400;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
	    .icon-event:before {
		    content: "\\e91b";
	    }
      .icon-arrow-down:before {
        content: "\\e901";
      }
      .icon-arrow-left:before {
        content: "\\e902";
      }
      .icon-arrow-right:before {
        content: "\\e903";
      }
      .icon-arrow-up:before {
        content: "\\e904";
      }
      .icon-breakfast_light:before {
        content: "\\e905";
      }
      .icon-dinner_light:before {
        content: "\\e906";
      }
      .icon-filter:before {
        content: "\\e907";
      }
      .icon-info:before {
        content: "\\e91a";
      }
      .icon-info-circle:before {
        content: "\\e909";
      }
      .icon-search:before {
        content: "\\e90d";
      }
      .icon-qr-code:before {
        content: "\\e90f";
      }
      .icon-spa_light:before {
        content: "\\e913";
      }
      .icon-area:before {
        content: "\\e914";
      }
      .icon-times:before {
        content: "\\e915";
      }
      .icon-guest:before {
        content: "\\e917";
      }
      .icon-wi-fi_light:before {
        content: "\\e919";
      }
`;
