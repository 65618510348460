import theme from './theme.json'
import { names } from './names'
import './hovers.css'

export const Styles = () => {
  const style = `
    .${names.backgrond.primary} {
      background-color: ${theme.background.primary}
    }

    .${names.backgrond.secondary} {
      background-color: ${theme.background.secondary}
    }

    .${names.backgrond.tertiary} {
      background-color: ${theme.background.tertiary}
    }

    .${names.backgrond.success} {
      background-color:  ${theme.background.success}
    }

    .${names.backgrond.negative} {
      background-color:  ${theme.background.negative}
    }


    .${names.backgrond.button.primaryLight} {
      background-color: ${theme.background.button.primaryLight}
    }

    .${names.backgrond.button.primary} {
      background-color: ${theme.background.button.primary}
    }

    .${names.backgrond.button.negative} {
      background-color: ${theme.background.button.negative}
    }

    .${names.backgrond.button.negativeLight} {
      background-color: ${theme.background.button.negativeLight}
    }

    .${names.backgrond.button.secondary} {
      background-color: ${theme.background.button.secondary}
    }

    .${names.backgrond.button.tertiary} {
      background-color: ${theme.background.button.tertiary}
    }

    .${names.backgrond.button.quaternary} {
      background-color: ${theme.background.button.quaternary}
    }

    .${names.backgrond.button.accent} {
      background-color: ${theme.background.button.accent}
    }

    .${names.backgrond.button.accentNegative} {
      background-color:  ${theme.background.button.accentNegative}
    }


    .${names.text.primary} {
      color: ${theme.text.primary}
    }

    .${names.text.secondary} {
      color: ${theme.text.secondary}
    }

    .${names.text.tertiary} {
      color: ${theme.text.tertiary}
    }

    .${names.text.tertiaryDark} {
      color: ${theme.text.tertiaryDark}
    }

    .${names.text.negative} {
      color: ${theme.text.negative}
    }

    .${names.text.accent} {
      color: ${theme.text.accent}
    }

    .${names.text.accentNegative} {
      color: ${theme.text.accentNegative}
    }


    .${names.icon.primary} {
      color: ${theme.icon}
    }

    .${names.icon.secondary} {
      color: ${theme.icon.secondary}
    }

    .${names.icon.tertiary} {
      color: ${theme.icon.tertiary}
    }

    .${names.icon.negative} {
      color: ${theme.icon.negative}
    }

    .${names.icon.accent} {
      color: ${theme.icon.accent}
    }

    .${names.icon.accentNegative} {
      color: ${theme.icon.accentNegative}
    }


    .${names.border.primary} {
      border-color: ${theme.border.primary}
    }

    .${names.border.secondary} {
      border-color: ${theme.border.secondary}
    }

    .${names.border.secondaryLight} {
      border-color: ${theme.border.secondaryLight}
    }

    .${names.border.tertiary} {
      border-color: ${theme.border.tertiary}
    }

    .${names.border.tertiaryDark} {
      border-color: ${theme.border.tertiaryDark}
    }

    .${names.border.accent} {
      border-color: ${theme.border.accent}
    }
  `
    .replaceAll('\n', '')
    .replaceAll(' ', '')

  const dataPickerStyles = `
      .react-datepicker__day{
        color: ${theme.text.primary}
      }
        
      .react-datepicker__header{
        background-color: ${theme.background.primary}
      }

      .react-datepicker__current-month{
        color: ${theme.text.primary}
      }
      
      .react-datepicker__day-name{
        color: ${theme.text.tertiaryLight}
      }

      .react-datepicker__day--disabled{
        color: ${theme.text.tertiaryLight}
      }
      

      .react-datepicker__day--range-end,
      .react-datepicker__day--range-start,
      .react-datepicker__day--selecting-range-end,
      .react-datepicker__day--selecting-range-start{
        color: ${theme.text.negative}
      }

      .react-datepicker__day--in-selecting-range:not(
      .react-datepicker__day--in-range, 
      .react-datepicker__month-text--in-range, 
      .react-datepicker__quarter-text--in-range, 
      .react-datepicker__year-text--in-range){
        background-color: ${theme.background.button.primaryLight}
      }

      .react-datepicker__day--range-start{
        color: ${theme.text.negative};
        background-color: ${theme.background.button.primary}
      }

      .react-datepicker__year-read-view--down-arrow,
      .react-datepicker__month-read-view--down-arrow,
      .react-datepicker__month-year-read-view--down-arrow,
      .react-datepicker__navigation-icon::before{
        border-color: ${theme.text.tertiaryLight}
      }
    `
    .replaceAll('\n', '')
    .replaceAll(' ', '')



  return <style>{style + dataPickerStyles}</style>
}