import React, { useEffect, useState } from "react";
import { useWindowSize } from "../hooks";
import HotelItem from "../HotelItem/HotelItem";
import HotelItemMobile from "../HotelItem/HotelItemMobile";
import "./style.css";
import AlertBox from "./AlertBox"
import Modal from 'react-modal';
import { names } from "../../Styles/names";

const LOADING_TIMEOUT = process.env.REACT_APP_LOADING_TIMEOUT
    ? Number(process.env.REACT_APP_LOADING_TIMEOUT) : 10000

function ResultContent(props) {
    const size = useWindowSize();
    let hotels = [];
    let quotas = 0;

    const [emailSubscription, setEmailSubscription] = useState('');
    const [name, setName] = useState(null);
    const [notAvail, setNotAvail] = useState(false);
    const [errorEmailValidation, setErrorEmailValidation] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [sendSucces, setSendSucces] = useState(false);
    const [resultRoomTypes, setResultRoomTypes] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [selectedRoomTypeIds, setSelectedRoomTypeIds] = useState([]);
    const { isDataTimeout, setIsDataTimeout } = props;

    //получаем категории с минимальными ценами для модалки
    useEffect(() => {
        if (props.result.length > 0) {
            const result = [];
            props.result.forEach(({ room_type_id, rate_group, room_type_name, quotas_vacant }) => {
                if (quotas_vacant != 0)
                    return;

                let minPrice = null;

                rate_group.forEach(({ rate_price }) => {
                    if (!minPrice || rate_price < minPrice)
                        minPrice = rate_price;
                });

                const el = {};
                el.id = room_type_id;
                el.name = room_type_name;
                el.price = 'от ' + minPrice.toLocaleString() + ' Р';

                result.push(el);
            });

            setResultRoomTypes(result);
        }
    }, [props.result])

    //создание подписки
    const addSubscription = () => {
        props.subscriptionParams.email = emailSubscription
        props.subscriptionParams.name = name

        if (selectedRoomTypeIds.length > 0) {
            props.subscriptionParams.room_type_id = selectedRoomTypeIds
        }
        fetch(`${process.env.REACT_APP_API_URL_DIRECTLY}api/subscriptions/add_subscription`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            }, mode: "cors",
            body: JSON.stringify(props.subscriptionParams),
        })
            .then((result) => result.json())
            .then((result) => {
                if (result?.errors) {
                    setErrorEmailValidation("email" in result.errors)
                    return
                }
                setErrorEmailValidation(false)
                if (result?.result === 'success') {
                    setSendSucces(true)
                } else if (result?.result === 'false') {
                    console.error(result.message)
                }
                setSelectedRoomTypeIds([])
            })
            .catch((error) => {
                setSelectedRoomTypeIds([])
                console.error(error.message)
            })
    };

    const handleCheckboxChange = (typeId) => {
        setSelectedRoomTypeIds((prevSelectedRoomTypeIds) => {
            if (prevSelectedRoomTypeIds.includes(typeId)) {
                return prevSelectedRoomTypeIds.filter((id) => id !== typeId);
            } else {
                return [...prevSelectedRoomTypeIds, typeId];
            }
        });
    };


    const emailSubscriptionHandler = (e) => {
        setEmailSubscription(e.target.value);
    };

    const dateIn = new Date(props.subscriptionParams.date_in);
    const dateOut = new Date(props.subscriptionParams.date_out);

    const formattedDate = `${padZero(dateIn.getDate())}.${padZero(dateIn.getMonth() + 1)}.${dateIn.getFullYear()} - ${padZero(dateOut.getDate())}.${padZero(dateOut.getMonth() + 1)}.${dateOut.getFullYear()}`;

    function padZero(value) {
        return value < 10 ? `0${value}` : value;
    }

    const adultsCount = props.subscriptionParams.adults;
    const childrenCount = props.subscriptionParams.children_info?.quantity ?? 0;

    let infoString = `${adultsCount} взрослых`;

    if (childrenCount > 0) {
        const childrenAges = Object.values(props.subscriptionParams.children_info)
            .filter((age, index) => index < childrenCount)
            .map(age => `${age} ${getAgeSuffix(age)}`)
            .join(', ');

        infoString += ` - ${childrenCount} ${getPluralForm(childrenCount, 'ребёнок', 'ребёнка', 'детей')} (${childrenAges})`;
    }

    function getAgeSuffix(age) {
        if (age === 1) {
            return 'год';
        } else if (age >= 2 && age <= 4) {
            return 'года';
        } else {
            return 'лет';
        }
    }

    function getPluralForm(quantity, oneForm, twoForm, fiveForm) {
        quantity = Math.abs(quantity) % 100;
        const remainder = quantity % 10;

        if (quantity > 10 && quantity < 20) {
            return fiveForm;
        }

        if (remainder > 1 && remainder < 5) {
            return twoForm;
        }

        if (remainder === 1) {
            return oneForm;
        }

        return fiveForm;
    }

    //боди модалки подписки
    const subscriptionModal =
        <div className={"subscription__modal"}>
            <span className={"title__subscription__modal"}>Узнавайте о доступности номеров первыми</span>
            <span className={"desc__subscription__modal"}>Укажите email, и мы отправим уведомление, если интересующий вас номер будет доступен для бронирования.</span>
            <div className={"dates__guests__subscription__container"}>
                <div className={"dates__guests__subscription__element"}>
                    {formattedDate}
                </div>
                <div className={"dates__guests__subscription__element"}>
                    {infoString}
                </div>
            </div>

            <div className={"hr__class"}></div>

            <input
                key={'emailSubscription'}
                value={emailSubscription}
                onChange={emailSubscriptionHandler}
                style={errorEmailValidation ? { border: '1px red solid' } : {}}
                className={"email__subscription"}
                placeholder={"Email"}
            />
            {errorEmailValidation && <span className={"error__email__validation"}>Некорректно введён Email</span>}
            <button onClick={() => addSubscription()}
                className={emailSubscription.length > 0 ? "button__subscription__pre__send" : "" + " button__subscription"}>
                Узнать первым
            </button>

            <span className={"subscription__footer"}>
                Нажимая кнопку «Узнать первым», вы соглашаетесь с Политикой конфиденциальности и условиями передачи персональных данных.
            </span>

            <div className={"room__type__container"}>
                <span className={"title__room__type__container"}>
                    {resultRoomTypes.length > 1 && 'Можете выбрать сразу несколько категорий номеров'}
                </span>

                {resultRoomTypes.length > 0 &&
                    resultRoomTypes.map((type, idx) => {
                        if (idx > 3 && !showMore)
                            return;

                        return <div key={type.id + '-room__type__element'} className={"room__type__element"}>
                            <div className={"room__type__name__price__container"}>
                                <input
                                    checked={selectedRoomTypeIds.includes(type.id)}
                                    onChange={() => handleCheckboxChange(type.id)}
                                    className={'room__type__input'} type="checkbox"
                                    id={'room__type-' + type.id} />
                                <label className={'room__type__name'} htmlFor={'room__type-' + type.id}>
                                    {type.name}
                                </label>
                            </div>
                            <span className={"room__type__price"}>{type.price}</span>
                        </div>
                    })
                }

                {resultRoomTypes.length > 4 && (
                    <button className={'show__more'} onClick={() => setShowMore(!showMore)}>{!showMore ? 'Показать ещё' : 'Скрыть'}</button>
                )}
            </div>
        </div>
        ;

    useEffect(() => {
        if (!openModal) {
            setTimeout(() => {
                setSendSucces(false);
            }, 1000);
        }
    }, [openModal])

    const handleCloseAll = () => {
        setOpenModal(false);
        setShowMore(false)
    };

    //боди модалки успешного создания подписки
    const sendSuccesContainer =
        <div className={"subscription__modal"}>
            <span className={"succes__title"}>Заявка принята</span>
            <span className={"succes__desc"}>Как только номера появятся в наличии, мы отправим уведомление на почту
                <b>{' ' + emailSubscription}</b>
            </span>

            <button onClick={handleCloseAll}
                className={"button__subscription__pre__send" + " button__subscription"}>
                Спасибо
            </button>

        </div>
        ;

    //открытие модалки и получение данных залогиненного юзера
    const subscriptionHandle = (roomTypeId = null) => {
        if (roomTypeId) {
            //сортируем, чтобы выбранный тип был вверху
            setResultRoomTypes((prResult => prResult.sort((a, b) => {
                if (a.id === roomTypeId) {
                    return -1;
                } else if (b.id === roomTypeId) {
                    return 1;
                } else {
                    return 0;
                }
            })
            ))

            setSelectedRoomTypeIds([roomTypeId])
        }
        setErrorEmailValidation(false)
        setOpenModal(true)
        fetch(`${process.env.REACT_APP_API_URL}index.php?route=api/customer/isLoggedUser`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then((result) => result.json())
            .then(result => {
                if (result?.isLogged) {
                    setName(result.data.firstname)
                    setEmailSubscription(result.data.email)
                }

                setErrorEmailValidation(false)
                setOpenModal(true)
            })
            .catch(error => {
                setErrorEmailValidation(false)
                setOpenModal(true)
                console.error(error);
            });
        setErrorEmailValidation(false)
        setOpenModal(true)
    };

    //понимаем нет вообще вариантов, или распроданы (тогда, все номера доступны к подписке)
    useEffect(() => {
        if (props?.result?.length === 0) {
            setNotAvail(true);
            const result = [];
            props?.roomTypes.length > 0 && props.roomTypes.forEach(({ id, name }) => {
                const el = {};
                el.id = id;
                el.name = name;
                el.price = '';

                result.push(el);
            });

            setResultRoomTypes(result);
        } else {
            setNotAvail(false);
        }
    }, [props?.result]);

    if (Array.isArray(props.result)) {
        const available = props.result.filter((room) => room.quotas_vacant > 0);
        available.sort((a, b) => a.order - b.order);

        const unavailable = props.result.filter(
            (room) => room.quotas_vacant === 0
        );
        unavailable.sort((a, b) => a.order - b.order);

        const result = [...available, ...unavailable];

        result.forEach(hotel => {
            let stopGeneral = false
            hotel.rate_group.forEach((rate) => {
                if (rate.own_quotas) {
                    quotas += rate.quotas_vacant;
                } else if (!stopGeneral) {
                    quotas += rate.quotas_vacant;
                    stopGeneral = true;
                }
            });
        })

        hotels = result.map((hotel, idx) => {
            const upsaleHotel = process.env.REACT_APP_API_UPSALES_ENABLED === 'true' ? result.filter(r => r.quotas_vacant > 0).find(upsaleHotel => hotel.total < upsaleHotel.total) : undefined
            if (size.width < 576) {
                return (
                    <HotelItemMobile
                        key={idx}
                        result={hotel}
                        productId={props.productId}
                        hotelId={props.hotelId}
                        onModalOpen={props.onModalOpen}
                        onModalClose={props.onModalClose}
                        upsaleProduct={upsaleHotel}
                        subscriptionHandle={subscriptionHandle}
                        syncMindBox={syncMindBox}
                    />
                );
            } else {
                return (
                    <HotelItem
                        key={idx}
                        result={hotel}
                        productId={props.productId}
                        hotelId={props.hotelId}
                        onModalOpen={props.onModalOpen}
                        onModalClose={props.onModalClose}
                        upsaleProduct={upsaleHotel}
                        subscriptionHandle={subscriptionHandle}
                        syncMindBox={syncMindBox}
                    />
                );
            }
        });
    }

    const handleTimeout = () => {
        setIsDataTimeout(true)
    }

    const load = (props.loader || isDataTimeout) &&
        (<LoadingStatus
            isShowPreloaderOnly={props.isShowPreloaderOnly}
            onTimeout={handleTimeout}
            isTimeout={isDataTimeout}
            isLoading={props.loader}
        />);

    const getWarningContainer = (message) => {
        return (
            <div className={`warning-new-container ${names.backgrond.secondary}`}>
                <div className="warning-new-display-flex">
                    <div className="warning-new-title-container">
                        <span className={`warning-new-no-rooms-title ${names.text.primary}`}>
                            Нет доступных номеров
                        </span>
                        <span
                            className={`warning-new-no-rooms-message ${names.text.tertiary}`}
                        >
                            {message}
                        </span>
                    </div>
                    <div
                        onClick={() => subscriptionHandle(null)}
                        className={`warning-new-button-container ${names.backgrond.button.negative} ${names.text.negative}`}
                    >
                        <span className="warning-new-notify-button">Уведомить о доступности</span>
                    </div>
                </div>
            </div>
        );
    };

    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.75)',
            zIndex: 1000,
        },
        content: {
            width: size.width < 576 ? '300px' : '500px',
            margin: size.width < 576 ? '' : 'auto',
            padding: '0',
            border: 'none',
            borderRadius: '8px',
            overflow: 'hidden',
            height: size.width < 576 ? '75vh' : 'fit-content'
        },
    };


    return (
        <>
            {props.isAlertBoxShown
                && <div className="alert-blocks">
                    <AlertBox text={props.alertText} size={size.width} />
                    <AlertBox text={props.agutinText} size={size.width} />
                </div>
            }

            <div className={`ur-row result-container ${names.backgrond.primary}`}>
                {load ||
                    (hotels.length ? (
                        <>
                            {hotels}
                        </>
                ) : (!props.agutinText && (
                            getWarningContainer(
                                "Доступных номеров для бронирования с указанными параметрами не найдено."
                            )
                        )
                    ))}
            </div>

            <Modal
                isOpen={openModal}
                onRequestClose={() => setOpenModal(false)}
                style={customStyles}
                contentLabel="Subscription Modal"
            >
                <div className={"new__modal " + (size.width < 576 ? 'new__modal__mobile' : '')}>
                    {!sendSucces && (
                        <div className="modal-header">
                        </div>
                    )}
                    <div className="modal-body">
                        {!sendSucces ? subscriptionModal : sendSuccesContainer}
                    </div>
                </div>
            </Modal>
        </>
    );
}

export default ResultContent;

const syncMindBox = (productId, count, price) => {
    window.mindbox('async', {
        operation: 'Website.SetCartList.JS',
        data: {
            productList: [
                {
                    product: {
                        ids: {
                            kpExternalSystem: productId
                        }
                    },
                    count: count,
                    pricePerItem: price
                }
            ]
        }
    });
}


const timeoutErrorText = 'Кажется, у нас проблема с подключением к серверу. Попробуйте обновить страницу.'

const preloaderCaptions = [
    'Запуск модуля бронирования',
    'Подготовка запроса',
    'Поиск вариантов',
    'Загрузка промокодов',
    'Обновление изображений',
    'Расчет доступных скидок',
    'Запуск дополнительных служб'
]
const captionsLastIndex = preloaderCaptions.length - 1


const LoadingStatus = ({ onTimeout, isTimeout, isLoading, isShowPreloaderOnly }) => {
    const [isTimeoutCheck, setIsTimeoutChek] = useState(false)
    const [isVisible, setIsVisible] = useState(true);
    const [isFadeOut, setIsFadeOut] = useState(false);
    const [captionIndex, setCaptionIndex] = useState(0);

    const classNames = ["preloader-container__right preloader-text"];
    const containerClassNames = ["preloader-container"];
    const containerTimeoutClassNames = ["preloader-container-timeout"];

    const switchVisibility = (newVisibility) => {
        if (newVisibility) {
            setIsVisible(true);
        } else {
            setIsFadeOut(true);
        }
    };

    const handleAnimationEnd = (e) => {
        if (e.animationName === "fadeOutAndUp") {
            setIsVisible(false);
            setIsFadeOut(false);

            setCaptionIndex(index => {
                if (index < captionsLastIndex) {
                    return index + 1
                } else {
                    return index
                }
            })
        }
    };

    const checkLoading = () => {
        const timer = setTimeout(() => {
            setIsTimeoutChek(true)
            clearTimeout(timer)
        }, LOADING_TIMEOUT)
    }

    useEffect(() => {
        switchVisibility(true)

        const timer = setTimeout(() => {
            if (captionIndex === captionsLastIndex) {
                checkLoading()
            }
            else {
                switchVisibility(false)
            }

            clearTimeout(timer)
        }, 300)
    }, [captionIndex])

    useEffect(() => {
        if (isTimeoutCheck && isLoading) {
            onTimeout()
        }
    }, [isTimeoutCheck, isLoading])


    if (isFadeOut) classNames.push("fade-out-and-up");

    if (isShowPreloaderOnly) {
        containerClassNames.push("preloader-only")
        containerTimeoutClassNames.push("preloader-only")
    }

    if (isTimeout) {
        return <div className={containerTimeoutClassNames.join(" ")}>
            <p className="preloader-text">
                {timeoutErrorText}
            </p>
        </div>
    }

    return <div className={containerClassNames.join(" ")}>
        <div className="preloader-container__left progress-circle__preloader progress-circle" />
        {isVisible && <p
            className={classNames.join(" ")}
            onAnimationEnd={handleAnimationEnd}
        >
            {preloaderCaptions[captionIndex]}
        </p>}
    </div>
}