// Имена классов для цветов
export const names = {
  "backgrond": {
    "primary": "backgrond-primary",
    "secondary": "backgrond-secondary",
    "tertiary": "backgrond-tertiary",
    "success": "backgrond-success",
    "negative": "backgrond-negative",
    "button": {
      "primaryLight": "backgrond-button-primary-light",
      "primary": "backgrond-button-primary",
      "negative": "backgrond-button-negative",
      "negativeLight": "backgrond-button-negative-light",
      "secondary": "backgrond-button-secondary",
      "tertiary": "backgrond-button-tertiary",
      "quaternary": "backgrond-button-quaternary",
      "accent": "backgrond-button-accent",
      "accentNegative": "backgrond-button-accent-negative"
    }
  },
  "text": {
    "primary": "text-color-primary",
    "secondary": "text-color-secondary",
    "tertiary": "text-color-tertiary",
    "tertiaryDark": "text-color-tertiary-dark",
    "negative": "text-color-negative",
    "accent": "text-color-accent",
    "accentNegative": "text-color-accent-negative"
  },
  "icon": {
    "primary": "icon-color-primary",
    "secondary": "icon-color-secondary",
    "tertiary": "icon-color-tertiary",
    "negative": "icon-color-negative",
    "accent": "icon-color-accent",
    "accentNegative": "icon-color-accent-negative"
  },
  "border": {
    "primary": "border-color-primary",
    "secondary": "border-color-secondary",
    "secondaryLight": "border-color-secondary-light",
    "tertiary": "border-color-tertiary",
    "tertiaryDark": "border-color-tertiary-dark",
    "accent": "border-color-accent"
  }
}