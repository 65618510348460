import React, { useState } from "react";
import moment from "moment";
import "moment/locale/ru";
import HotelModal from "../HotelItem/HotelModal";
import Slider from "react-slick";
import "./style-mobile.css";
import { names } from "../../Styles/names";

function HotelItemMobile(props) {
  const [isHotelOpen, setHotelOpen] = useState(false);
  const [showFeatures, setShowFeatures] = useState(false);

  if (!props.result.gallery_images) return <></>;

  const diff = moment(props.result.date_out).diff(
    moment(props.result.date_in),
    "days"
  );

  const minPrice = []
  const prePrice = {}
  const prePriceTwo = {}
  const temp = []
  let mainQuotasVacant = 0
  let stopGeneral = false //после добавления первой общей квоты, не плюсуем
  props.result.rate_group.forEach((rate) => {
    if (rate.related_rate === 1) {
      prePrice.rate_price = rate.rate_price
    } else if (rate.related_rate === 2) {
      prePriceTwo.rate_price = rate.rate_price
      minPrice.push(rate.rate_price + prePrice.rate_price)
    } else if (rate.related_rate === 3) {
      minPrice.pop()
      minPrice.push(
          rate.rate_price + prePrice.rate_price + prePriceTwo.rate_price
      )
    } else {
      if (rate.total_discount.price !== 0)
        minPrice.push(rate.total_discount.price)
      else
        minPrice.push(rate.rate_price)
    }

    temp.push(rate.related_rate)

    if (rate.own_quotas) {
      mainQuotasVacant += rate.quotas_vacant
    } else if (!stopGeneral) {
      mainQuotasVacant += rate.quotas_vacant
      stopGeneral = true
    }
  })

  const minCost = Math.min.apply(null, minPrice)

  function declOfNum(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2];
    return titles[
      number % 100 > 4 && number % 100 < 20
        ? 2
        : cases[number % 10 < 5 ? number % 10 : 5]
    ];
  }

  const soldOutContainer =
      <div className={"sold__out__content"}>
        <div className={"sold__out__soldOut"}>Распродано</div>
        <div onClick={() => props.subscriptionHandle(props.result.room_type_id)} className={"subscription-button__mobile"}>Уведомить о доступности</div>
      </div>

  return (
    <React.Fragment>
      <div className={`result-container-mobile__hotel ${names.border.tertiary}`}>
        <div className="result-container__hotel-main">
          <div className="images-gallery">
            <div className="main-image">
              {props.result.image == '' ?
                  <div className="ur-text-center">
                    <div className="progress-circle progress-circle__mobile"/>
                  </div>
                  :
                  <Slider
                      {...{
                        dots: true,
                        arrows: false,
                        speed: 500,
                        infinite: false,
                        slidesToShow: 1,
                        slidesToScroll: 1,
                      }}
                  >
                    {props.result.gallery_images.map((image, idx) => (
                        <img
                            key={idx}
                            src={process.env.REACT_APP_API_URL_DIRECTLY + image.link}
                            alt={props.result.room_type_name}
                            loading="lazy"
                        />
                    ))}
                  </Slider>}
            </div>
          </div>
          <div className="hotel-content">
            <div className="hotel-content__title">
              <button onClick={setHotelOpen} className={names.text.secondary}>
                {props.result.room_type_name}
              </button>
            </div>
            <div
              className={[
                "hotel-content__last-number",
                names.backgrond.button.negativeLight,
                names.text.negative,
                `${mainQuotasVacant > 0 && mainQuotasVacant < 5
                  ? "" :
                  " ur-d-none"
                }`
              ].join(' ')}
            >
              {/*{mainQuotasVacant <= 0 && "Нет свободных номеров"}*/}
              {mainQuotasVacant === 1 && "Остался всего 1 номер"}
              {mainQuotasVacant >= 2 &&
                  "Осталось всего " + mainQuotasVacant + " номера"}
            </div>
            <div className="hotel-content__options">
              <div className={`options-item ${names.text.secondary}`}>
                <i className="icon icon-guest"></i> до{" "}
                {props.result.room_type_capacity} человек
              </div>
              <div className={`options-item ${names.text.secondary}`}>
                <i className="icon icon-area"></i>{" "}
                {props.result.room_type_square[0] ===
                props.result.room_type_square[1] ? (
                    <>{props.result.room_type_square[0]}м²</>
                ) : (
                    <>
                      {props.result.room_type_square[0]}м²
                      {" - "}
                      {props.result.room_type_square[1]}м²
                    </>
                )}
              </div>
            </div>

            <button className={`features__button__mobile ${names.text.accentNegative}`}
                    onClick={() => setShowFeatures(pr => !pr)}>{showFeatures ? 'Скрыть' : 'Показать'} список
              удобств
            </button>

            {showFeatures && props.result?.room_feature_categories && (
                <div className="feature__container__mobile">
                  {Object.entries(props.result?.room_feature_categories).map(([key, value]) => (
                      <div className="feature__element__mobile" key={key}>
                      <div className={`feature__category__name__mobile ${names.text.tertiary}`}>{key}</div>
                      <div className={`features__mobile ${names.backgrond.secondary}`}>
                          {value.map(v => (
                              <div className="internal__feature__mobile" key={v.icon}>
                                <img
                                    src={`${process.env.REACT_APP_API_URL_DIRECTLY}feature_icons/${encodeURIComponent(v.icon)}`}
                                    alt=""
                                    loading="lazy"
                                />
                              <div className={`feature__name__mobile ${names.text.primary}`}>
                                {v.name}
                              </div>
                              </div>
                          ))}
                        </div>
                      </div>
                  ))}
                </div>
            )}

            <div className="hotel-content__footer">
              {mainQuotasVacant <= 0 && soldOutContainer}
              <div className="col-left">
                {mainQuotasVacant > 0 &&
                    <>
                      <div className="col-discount ur-d-none">
                        <span className="price-percent">-5%</span>
                        <span className="price-discount ur-d-block ur-mt-7px">
                    {(
                        Math.round(props.result.total) +
                        (Math.round(props.result.total) / 100) * 5
                    ).toLocaleString()}{" "}
                          ₽
                  </span>
                      </div>
                  <div className={`col-price ${names.text.secondary}`}>
                        {/*{(props.result.extraPlace !== null || props.result.cradlePlace !== null) && "от "}*/}
                        от {Math.round(minCost).toLocaleString()} ₽
                      </div>
                  <div className={`col-desc ${names.text.tertiaryDark}`}>
                        за {diff} {declOfNum(diff, ["ночь", "ночи", "ночей"])}
                      </div>
                    </>
                }
              </div>
              <div className="col-right">
                <div className="col-buy">
                  {mainQuotasVacant > 0 &&
                      <button
                          onClick={setHotelOpen}
                          disabled={mainQuotasVacant === 0}
                          className={
                            "ur-btn ur-btn-buy " +
                            (mainQuotasVacant > 0
                              ? `${names.backgrond.button.accent} ${names.text.negative}`
                              : `${names.backgrond.button.tertiary} ${names.text.secondary}`)
                          }
                      >
                        {mainQuotasVacant > 0 ? 'Выбрать' : 'Распродано'}
                      </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <HotelModal
        result={props.result}
        productId={props.productId}
        hotelId={props.hotelId}
        modal={isHotelOpen}
        onModalShowChange={setHotelOpen}
        onModalOpen={props.onModalOpen}
        onModalClose={props.onModalClose}
				upsaleProduct={props.upsaleProduct}
        temp={temp}
        syncMindBox={props.syncMindBox}
      />
    </React.Fragment>
  );
}

export default HotelItemMobile;